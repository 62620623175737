import React from 'react';
import ReactDOM from 'react-dom';

import loadScript from 'load-script';
import App from './components/App';
import utils from './utils/utils';
// eslint-disable-next-line
import css from './index.css';

// eslint-disable-next-line
String.prototype.contains = function(a, b) {
  return b ? (b + this + b).indexOf(b + a + b) > -1 : this.indexOf(a) > -1;
};

const setupCKEditor = () => {
  window.CKEDITOR.config.linkShowAdvancedTab = false;
  window.CKEDITOR.config.linkShowTargetTab = false;
  window.CKEDITOR.config.image_previewText = ' ';

  const pluginName = 'strinsert';
  window.CKEDITOR.config.strinsert_button_label = 'smartmessaging.contentEditor.addValue';
  window.CKEDITOR.config.strinsert_button_title = 'Insert content';
  window.CKEDITOR.config.strinsert_button_voice = 'Insert content';

  window.CKEDITOR.plugins.add(pluginName, {
    requires: ['richcombo'],
    init(editor) {
      const { config } = editor;
      // Gets the list of insertable strings from the settings.
      // add the menu to the editor
      editor.ui.addRichCombo('strinsert', {
        label: utils.getLang(config.strinsert_button_label),
        title: config.strinsert_button_title,
        voiceLabel: config.strinsert_button_voice,
        toolbar: 'insert',
        className: 'cke_format',
        multiSelect: false,
        panel: {
          css: [config.contentsCss, window.CKEDITOR.skin.getPath('editor')],
          voiceLabel: editor.lang.panelVoiceLabel,
        },
        init() {
          let { fields } = editor.config;

          fields = fields.sort((field1, field2) => {
            if (!field2.name) return -1;
            if (!field1.name) return 1;
            return `${field1.name}`.localeCompare(field2.name);
          });

          fields.forEach(string => {
            // If there is no value, make a group header using the name.
            const str = { ...string };
            if (!string.value) {
              this.startGroup(string.name);
            }
            // If we have a value, we have a string insert row.
            else {
              // If no name provided, use the value for the name.
              if (!str.name) {
                str.name = str.value;
              }
              // If no label provided, use the name for the label.
              if (!str.label) {
                str.label = str.name;
              }
              this.add(str.value, str.name, str.label);
            }
          });
        },
        onClick(value) {
          // editor.focus();
          // editor.fire('saveSnapshot');
          editor.insertHtml(' ');
          editor.insertElement(window.CKEDITOR.dom.element.createFromHtml(value));
          // editor.fire('saveSnapshot');
        },
      });
    },
  });
};

loadScript('/ckeditor/ckeditor.js', () => {
  setupCKEditor();
  ReactDOM.render(<App />, document.getElementById('root'));

  if (module.hot) {
    module.hot.accept('./components/App', () => {
      ReactDOM.render(<App />, document.getElementById('root'));
    });
  }
});
