import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import utils from 'src/utils/utils';
import { defaultCkeHtml } from 'src/components/common/ReactCKEditor';
import { useTheme } from '@mui/material/styles';
import useRenderEditor from 'src/hooks/useRenderEditor';
import EmailObjectEditor from 'src/components/common/contentEditor/EmailObjectEditor';
import EmailContentEditor from 'src/components/common/contentEditor/EmailContentEditor';

const EmailEditor = ({
  fields,
  content,
  object,
  onEditorLoaded,
  onObjectEditorLoaded,
  onChange,
  readOnly,
}) => {
  const theme = useTheme();
  const { container, rendered } = useRenderEditor();
  return (
    <div style={{ height: '85%' }} ref={container}>
      {rendered && (
        <>
          <Typography
            style={{ margin: '5px 10px', color: theme.palette.text.secondary }}
            variant="subtitle2"
            noWrap
          >
            {utils.getLang('smartmessaging.contentEditor.mail.object')}
          </Typography>
          <EmailObjectEditor
            container={container}
            config={{
              readOnly: !!readOnly,
              fields: fields.fields.objectFields,
            }}
            events={{
              change: onChange,
              loaded: event => {
                onObjectEditorLoaded(event.editor);
              },
            }}
            object={object}
          />
          <Typography
            variant="subtitle2"
            style={{ paddingTop: '16px', margin: '5px 10px', color: theme.palette.text.secondary }}
            noWrap
          >
            {utils.getLang('smartmessaging.contentEditor.mail.content')}
          </Typography>
          <EmailContentEditor
            container={container}
            config={{
              readOnly: !!readOnly,
              fullPage: true,
              allowedContent: true,
              fields: fields.fields.contentFields,
            }}
            events={{
              loaded: event => {
                onEditorLoaded(event.editor);
              },
              change: onChange,
              instanceReady: event => {
                if (content) event.editor.setData(content);
                else event.editor.setData(defaultCkeHtml);
              },
            }}
          />
        </>
      )}
    </div>
  );
};

EmailEditor.propTypes = {
  fields: PropTypes.any.isRequired,
  content: PropTypes.string.isRequired,
  object: PropTypes.string,
  onEditorLoaded: PropTypes.func.isRequired,
  onObjectEditorLoaded: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

EmailEditor.defaultProps = {
  object: '',
  readOnly: false,
};

export default EmailEditor;
