import React from 'react';
import PropTypes from 'prop-types';
import NotifyObjectLabel from './NotifyObjectLabel';
import NotifyContentLabel from './NotifyContentLabel';

import EmailObjectEditor from 'src/components/common/contentEditor/EmailObjectEditor';
import useRenderEditor from 'src/hooks/useRenderEditor';
import MemberNotificationContentEditor from 'src/components/common/contentEditor/MemberNotificationContentEditor';

const MassActionNotifyEditorRender = ({
  fields,
  content = '',
  object,
  onEditorLoaded,
  onObjectEditorLoaded,
  onChange,
  isWritable,
}) => {
  const { container, rendered } = useRenderEditor();

  return (
    <div
      style={{
        height: '70%',
        minHeight: '600px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        overflow: 'visible',
      }}
      ref={container}
    >
      {rendered && (
        <>
          <div>
            <NotifyObjectLabel />
            <EmailObjectEditor
              container={container}
              config={{
                readOnly: !isWritable,
                fields: fields.fields.objectFields,
              }}
              events={{
                loaded: event => {
                  onObjectEditorLoaded(event.editor);
                },
                change: onChange,
              }}
              object={object}
            />
          </div>
          <div>
            <NotifyContentLabel />
            <MemberNotificationContentEditor
              container={container}
              content={content}
              config={{
                readOnly: !isWritable,
                fields: fields.fields.contentFields,
              }}
              events={{
                loaded: event => {
                  onEditorLoaded(event.editor);
                },
                change: onChange,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

MassActionNotifyEditorRender.propTypes = {
  fields: PropTypes.any.isRequired,
  content: PropTypes.string,
  object: PropTypes.string,
  onEditorLoaded: PropTypes.func.isRequired,
  onObjectEditorLoaded: PropTypes.func.isRequired,
  isWritable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

MassActionNotifyEditorRender.defaultProps = {
  object: '',
  content: '',
};

export default MassActionNotifyEditorRender;
