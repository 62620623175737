import React from 'react';
import CKEditor from '../ReactCKEditor';
import { CKEditorEvent } from 'src/types/types';

interface EmailContentEditorProps {
  events?: { [key: string]: (ckEvent: CKEditorEvent) => void };
  config?: { [key: string]: unknown };
  name?: string;
  container: React.MutableRefObject<HTMLDivElement>;
}

const EmailContentEditor = ({
  name = 'htmlEditor',
  container,
  events = {},
  config = {},
}: EmailContentEditorProps) =>
  Boolean(container?.current) && (
    <CKEditor
      name={name}
      config={{
        fullPage: true,
        allowedContent: true,
        removeButtons:
          'Underline,Subscript,Superscript,Anchor,About,Copy,Paste,PasteText,PasteFromWord,Cut,Scayt',
        disallowedContent: 'img{border*,margin*,float*,width,height}; table{width,height}',
        extraAllowedContent: 'script; img[width,height]; table[width,height]',
        extraPlugins: 'strinsert,uploadimage',
        imageUploadUrl: `${process.env.REACT_APP_API_BASE_URL}/storedfile/fileUpload/`,
        filebrowserImageUploadUrl: `${process.env.REACT_APP_API_BASE_URL}/storedfile/fileUpload/`,
        removePlugins: 'elementspath',
        autoParagraph: false,
        enterMode: 2,
        height: 70 * (container.current.clientHeight / 100),
        protectedSource: /<!\[CDATA\[[\s\S]*?\]\]>/g,
        ...config,
      }}
      events={{
        pluginsLoaded(event) {
          event.editor.dataProcessor.dataFilter.addRules({
            elements: {
              script() {
                return false;
              },
            },
          });
        },
        fileUploadRequest: event => {
          const { xhr, file, fileName } = event.data.fileLoader;
          const smStore = JSON.parse(sessionStorage.getItem('sm-store'));
          xhr.setRequestHeader('Authorization', `Bearer ${smStore.security.access_token}`);
          xhr.setRequestHeader(
            'X-User-Club-Id',
            `/${smStore.client}/clubs/${sessionStorage.getItem('club')}`
          );

          const networknode = sessionStorage.getItem('networknode');
          if (networknode)
            xhr.setRequestHeader(
              'X-User-Network-Node-Id',
              `/${smStore.client}/network_nodes/${networknode}`
            );
          const formData = new FormData();
          formData.append('file', file, fileName);
          xhr.send(formData);
          event.stop();
        },
        fileUploadResponse: event => {
          event.stop();
          const { data } = event;
          const { responseText } = event.data.fileLoader.xhr;
          data.url = `${process.env.REACT_APP_API_BASE_URL}/media/${responseText}`;
        },
        ...events,
      }}
    />
  );

export default EmailContentEditor;
