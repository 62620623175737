import React from 'react';
import PropTypes from 'prop-types';
import SmsValidity from './SmsValidity';

import SmsEditor from 'src/components/common/contentEditor/SmsEditor';
import useRenderEditor from 'src/hooks/useRenderEditor';

const MassActionSMSEditorRender = ({ fields, content, onEditorLoaded, onChange, isWritable }) => {
  const { container, rendered } = useRenderEditor(false);
  return (
    <div
      style={{
        height: '85%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        flex: 1,
        overflow: 'visible',
      }}
      ref={container}
    >
      {rendered && (
        <div>
          <SmsEditor
            config={{
              readOnly: !isWritable,
              fields: fields.fields,
            }}
            events={{
              loaded: e => {
                onEditorLoaded(e.editor);
              },
              instanceReady: event => {
                if (content) {
                  event.editor.setData(content);
                }
                setTimeout(() => event.editor.on('change', onChange), 1000);
              },
            }}
          />
          <SmsValidity />
        </div>
      )}
    </div>
  );
};

MassActionSMSEditorRender.propTypes = {
  fields: PropTypes.any.isRequired,
  content: PropTypes.string.isRequired,
  onEditorLoaded: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

export default MassActionSMSEditorRender;
