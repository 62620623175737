/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from './Loader';

import getApiCaller from 'src/utils/apiClientCaller';

class DataResolverCmp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resolved: false,
      data: null,
    };
    this.setResolved = this.setResolved.bind(this);
    this.setData = this.setData.bind(this);
    this.update = this.update.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.update();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setResolved(resolved) {
    this.setState({ resolved });
  }

  setData(data) {
    this.setState({ data });
  }

  update() {
    const { resolve, onResolved, doGetApiCaller, showAlert } = this.props;
    this.setResolved(false);
    const apiCall = doGetApiCaller(showAlert);
    resolve(apiCall, showAlert).then(async data => {
      await onResolved(data).then(() => {
        if (this._isMounted) {
          this.setData(data);
          this.setResolved(true);
        }
      });
    });
  }

  render() {
    const { resolved } = this.state;
    const {
      loaderWrapperClass,
      loaderWrapperStyle,
      loaderText,
      getLoaderText,
      children,
      loader,
      doGetApiCaller,
      showAlert,
    } = this.props;
    return resolved
      ? children({
          ...this.state,
          update: this.update,
          setData: this.setData,
          callApi: doGetApiCaller(showAlert),
        })
      : ((loader === true || loader === undefined) && (
          <Loader
            text={loaderText || (getLoaderText && getLoaderText()) || ''}
            wrapperClass={loaderWrapperClass}
            wrapperStyle={loaderWrapperStyle}
          />
        )) ||
          (loader && loader) ||
          null;
  }
}

DataResolverCmp.propTypes = {
  children: PropTypes.func.isRequired,
  loaderWrapperClass: PropTypes.string,
  loaderWrapperStyle: PropTypes.object,
  loaderText: PropTypes.string,
  loader: PropTypes.any,
  getLoaderText: PropTypes.func,
  resolve: PropTypes.func.isRequired,
  onResolved: PropTypes.func.isRequired,
  doGetApiCaller: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
};

DataResolverCmp.defaultProps = {
  loaderWrapperClass: null,
  loaderWrapperStyle: null,
  getLoaderText: null,
  loaderText: '',
  loader: true,
};

export default connect(null, {
  doGetApiCaller: getApiCaller,
})(DataResolverCmp);
