import React from 'react';
import PropTypes from 'prop-types';
import EmailObjectLabel from './EmailObjectLabel';
import EmailContentLabel from './EmailContentLabel';

import CtnLang from './CtnLang';
import { defaultCkeHtml } from 'src/components/common/ReactCKEditor';
import utils from 'src/utils/utils';
import EmailObjectEditor from 'src/components/common/contentEditor/EmailObjectEditor';
import EmailContentEditor from 'src/components/common/contentEditor/EmailContentEditor';
import useRenderEditor from 'src/hooks/useRenderEditor';

const MassActionMailEditorRender = ({
  fields,
  content,
  object,
  onEditorLoaded,
  onObjectEditorLoaded,
  onChange,
  isWritable,
}) => {
  const { container, rendered } = useRenderEditor();

  return (
    <div
      style={{
        height: '70%',
        minHeight: '600px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        overflow: 'visible',
      }}
      ref={container}
    >
      {rendered && (
        <>
          <div>
            <div style={{ width: '100%', display: 'flex' }}>
              <EmailObjectLabel title={utils.getLang('smartmessaging.contentEditor.mail.object')} />
              <div style={{ marginRight: 0, marginLeft: 'auto' }}>
                <CtnLang />
              </div>
            </div>
            <EmailObjectEditor
              container={container}
              config={{
                readOnly: !isWritable,
                fields: fields.fields.objectFields,
              }}
              events={{
                loaded: event => {
                  onObjectEditorLoaded(event.editor);
                },
                change: onChange,
              }}
              object={object}
            />
          </div>
          <div>
            <EmailContentLabel title={utils.getLang('smartmessaging.contentEditor.mail.content')} />
            <EmailContentEditor
              container={container}
              config={{
                readOnly: !isWritable,
                fields: fields.fields.contentFields,
              }}
              events={{
                loaded: event => {
                  onEditorLoaded(event.editor);
                },
                change: onChange,
                instanceReady: event => {
                  if (content) event.editor.setData(content);
                  else event.editor.setData(defaultCkeHtml);
                },
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

MassActionMailEditorRender.propTypes = {
  fields: PropTypes.any.isRequired,
  content: PropTypes.string.isRequired,
  object: PropTypes.string,
  onEditorLoaded: PropTypes.func.isRequired,
  onObjectEditorLoaded: PropTypes.func.isRequired,
  isWritable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

MassActionMailEditorRender.defaultProps = {
  object: '',
};

export default MassActionMailEditorRender;
