import uuid from 'uuid';
import utils from 'src/utils/utils';
import smsUtils from 'src/components/common/contentEditor/utils/smsUtils';
import ParameterType from 'src/enums/parameterType';
import OperatorType from 'src/enums/operatorType';
import ActionType from 'src/enums/actionType';
import contentEditorUtils from 'src/components/common/contentEditor/utils/contentEditorUtils';

const mapCriteriasByKey = criteriaList =>
  criteriaList.reduce(
    (currentResult, criteria) => ({ ...currentResult, [criteria.key]: criteria }),
    {}
  );

const mapAvailableCriterias = criteriaList =>
  criteriaList.reduce((lastResult, criteria) => {
    const availableCriteriaMap = { ...lastResult };
    if (!availableCriteriaMap[criteria.groupType]) {
      availableCriteriaMap[criteria.groupType] = [];
    }
    switch (ParameterType[criteria.type]) {
      // TO DO CriteriaTypeEnum
      case ParameterType.GROUP: {
        // groupMandatories: liste des descripteurs mandatories du groupe si ce critere est sélectionné
        const groupMandatories = criteria.descriptors.reduce(
          (acc, current) =>
            current.mandatory
              ? acc.concat({
                  ...current,
                  parentDescriptor: { ...criteria },
                })
              : acc,
          []
        );
        criteria.descriptors.forEach(groupedCriteria =>
          availableCriteriaMap[criteria.groupType].push({
            ...groupedCriteria,
            parentDescriptor: { ...criteria },
            groupMandatories,
          })
        );

        break;
      }
      default:
        availableCriteriaMap[criteria.groupType].push(criteria);
    }

    return availableCriteriaMap;
  }, {});

const mapMandatoryCriterias = criteriaList =>
  criteriaList.filter(criteria => criteria.mandatory).map(filteredCriteria => filteredCriteria.key);

/**  */
const getSelectedCriteriasFromValue = (setupValue, criteriaByKey) =>
  setupValue.reduce((lastResult, singleValue) => {
    const newResult = { ...lastResult };
    const model = criteriaByKey[singleValue.key];

    if (!newResult[model.groupType]) newResult[model.groupType] = {};
    if (!newResult[model.groupType][singleValue.key]) {
      newResult[model.groupType][singleValue.key] = { model, criteriaValues: {} };
    }

    switch (ParameterType[model.type]) {
      case ParameterType.GROUP: {
        const groupUniqueId = uuid();
        newResult[model.groupType][singleValue.key].criteriaValues[
          groupUniqueId
        ] = getSelectedCriteriasFromValue(singleValue.values, mapCriteriasByKey(model.descriptors))[
          model.groupType
        ];

        if (!newResult[model.groupType][singleValue.key].groupDisplayNames) {
          newResult[model.groupType][singleValue.key].groupDisplayNames = {};
        }

        newResult[model.groupType][singleValue.key].groupDisplayNames = {
          ...newResult[model.groupType][singleValue.key].groupDisplayNames,
          [groupUniqueId]: utils.stringFormat(
            utils.getLang('smartmessaging.massAction.groupNaming'),
            [
              Object.values(newResult[model.groupType][singleValue.key].groupDisplayNames).length +
                1,
            ]
          ),
        };

        break;
      }
      default:
        newResult[model.groupType][singleValue.key].criteriaValues[uuid()] = singleValue;
    }

    return newResult;
  }, {});

const getSelectedColumnsFromList = (fieldList, groupedAvailableFields) => {
  const groupsByFields = Object.entries(groupedAvailableFields).reduce(
    (acc, [group, availaibleFields]) => {
      const currentGroupByFields = availaibleFields.reduce(
        (subAcc, field) => ({ ...subAcc, [field.value]: { group, type: field.type } }),
        {}
      );

      return { ...acc, ...currentGroupByFields };
    },
    {}
  );

  const fieldSelection = fieldList.reduce((acc, current) => {
    const reduced = { ...acc };
    if (!reduced[groupsByFields[current].group]) {
      reduced[groupsByFields[current].group] = [];
    }
    reduced[groupsByFields[current].group].push({
      label: utils.getLang(`smartmessaging.resultfield.${current}`),
      value: current,
      type: groupsByFields[current].type,
    });
    return reduced;
  }, {});

  return fieldSelection;
};

const addSingleSelectedCriteria = (value, state, forcedGroupTypeName) => {
  function getInitialValue() {
    if (value.allowedOperators.length === 1) {
      switch (OperatorType[value.allowedOperators[0]]) {
        case OperatorType.TRUE:
          return ['true'];
        case OperatorType.FALSE:
          return ['false'];
        default:
          return [];
      }
    }
    return [];
  }
  const groupTypeName = forcedGroupTypeName || value.groupType;
  const operatorId =
    (value.allowedOperators.length === 1 && OperatorType[value.allowedOperators[0]]) || null;
  const uniqueId = uuid();
  const rez = {
    selectedCriterias: {
      ...state.selectedCriterias,
      [groupTypeName]: state.selectedCriterias[groupTypeName]
        ? {
            ...state.selectedCriterias[groupTypeName],
            [value.key]: state.selectedCriterias[groupTypeName][value.key] // Si le critere a déjà été sélectionné et existe déjà dans ce groupe de critères sélectionnés
              ? {
                  // on rajoute seulement une valeur vide aux valeurs du critere
                  ...state.selectedCriterias[groupTypeName][value.key],
                  criteriaValues: {
                    ...state.selectedCriterias[groupTypeName][value.key].criteriaValues,
                    [uniqueId]: { operatorId, values: getInitialValue() },
                  },
                }
              : // sinon on ajoute une entrée pour ce critère dans le groupe sélectionné, avec une valeur vide dans les valeurs pour ce critère
                {
                  model: value,
                  criteriaValues: { [uniqueId]: { operatorId, values: getInitialValue() } },
                },
          }
        : {
            [value.key]: {
              model: value,
              criteriaValues: { [uniqueId]: { operatorId, values: getInitialValue() } },
            },
          },
    },
  };
  return rez.selectedCriterias;
};

const addGroupedCriteria = (value, state) => {
  const rez = { selectedCriterias: { ...state.selectedCriterias } };
  const addMandatories = !value.groupValueUniqueId; // le champ n'a pas de groupUniqueId = nouveau groupe qui contiendra ces champs + les mandatories du groupe
  const groupCriteriaValueItemUUID = value.groupValueUniqueId || uuid();

  // si selectedCRITERIA[GROUPtYPE] existe PAS, on le créé
  if (!rez.selectedCriterias[value.groupType]) {
    rez.selectedCriterias[value.groupType] = {};
  }

  // si selectedCRITERIA[GROUPtYPE][value.parentDescriptor.key] n'existe pas, on le crée. on ajoute une val a ses criteriavalues
  if (!rez.selectedCriterias[value.groupType][value.parentDescriptor.key]) {
    rez.selectedCriterias[value.groupType][value.parentDescriptor.key] = {
      groupDisplayNames: {},
      criteriaValues: {},
      model: value.parentDescriptor,
    };
  }

  if (addMandatories) {
    value.groupMandatories.forEach(m => {
      rez.selectedCriterias = {
        ...rez.selectedCriterias,
        [value.groupType]: {
          ...rez.selectedCriterias[value.groupType],
          [value.parentDescriptor.key]: {
            ...rez.selectedCriterias[value.groupType][value.parentDescriptor.key],
            criteriaValues: {
              ...addSingleSelectedCriteria(
                m,
                {
                  selectedCriterias: {
                    ...rez.selectedCriterias[value.groupType][value.parentDescriptor.key]
                      .criteriaValues,
                  },
                },
                groupCriteriaValueItemUUID
              ),
            },
          },
        },
      };
    });
  }

  if (!value.mandatory || !addMandatories) {
    rez.selectedCriterias = {
      ...rez.selectedCriterias,
      [value.groupType]: {
        ...rez.selectedCriterias[value.groupType],
        [value.parentDescriptor.key]: {
          ...rez.selectedCriterias[value.groupType][value.parentDescriptor.key],
          criteriaValues: {
            ...rez.selectedCriterias[value.groupType][value.parentDescriptor.key].criteriaValues,
            ...addSingleSelectedCriteria(
              value,
              {
                selectedCriterias: {
                  ...rez.selectedCriterias[value.groupType][value.parentDescriptor.key]
                    .criteriaValues,
                },
              },
              groupCriteriaValueItemUUID
            ),
          },
        },
      },
    };
  }

  if (
    !rez.selectedCriterias[value.groupType][value.parentDescriptor.key].groupDisplayNames[
      groupCriteriaValueItemUUID
    ]
  ) {
    rez.selectedCriterias[value.groupType][value.parentDescriptor.key].groupDisplayNames = {
      ...rez.selectedCriterias[value.groupType][value.parentDescriptor.key].groupDisplayNames,
      [groupCriteriaValueItemUUID]: utils.stringFormat(
        utils.getLang('smartmessaging.massAction.groupNaming'),
        [
          Object.values(
            rez.selectedCriterias[value.groupType][value.parentDescriptor.key].groupDisplayNames
          ).length + 1,
        ]
      ),
    };
  }
  return rez.selectedCriterias;
};

const addSelectedCriteria = (value, state) => {
  if (value.parentDescriptor) {
    return addGroupedCriteria(value, state);
  }
  return addSingleSelectedCriteria(value, state);
};

const addAllMandatories = state => {
  const { mandatoryCriterias, criteriaByKey } = state;
  const list = mandatoryCriterias.map(mc => criteriaByKey[mc]);
  let selectedList = {};
  list.forEach(criteria => {
    selectedList = {
      ...selectedList,
      ...addSelectedCriteria(criteria, { selectedCriterias: selectedList }),
    };
  });
  return selectedList;
};

const changeDateValue = ({ newValue, uniqueId, model, groupName }, selectedCriterias) => ({
  ...selectedCriterias,
  [groupName]: {
    ...selectedCriterias[groupName],
    [model.key]: {
      ...selectedCriterias[groupName][model.key],
      criteriaValues: {
        ...selectedCriterias[groupName][model.key].criteriaValues,
        [uniqueId]: {
          ...selectedCriterias[groupName][model.key].criteriaValues[uniqueId],
          values: [].concat(newValue),
        },
      },
    },
  },
});

const changeStringValue = ({ newValue, uniqueId, model, groupName }, selectedCriterias) => ({
  ...selectedCriterias,
  [groupName]: {
    ...selectedCriterias[groupName],
    [model.key]: {
      ...selectedCriterias[groupName][model.key],
      criteriaValues: {
        ...selectedCriterias[groupName][model.key].criteriaValues,
        [uniqueId]: {
          ...selectedCriterias[groupName][model.key].criteriaValues[uniqueId],
          values: [].concat(newValue),
        },
      },
    },
  },
});

const changeClubsValue = ({ newValue, uniqueId, model, groupName }, selectedCriterias) => ({
  ...selectedCriterias,
  [groupName]: {
    ...selectedCriterias[groupName],
    [model.key]: {
      ...selectedCriterias[groupName][model.key],
      criteriaValues: {
        ...selectedCriterias[groupName][model.key].criteriaValues,
        [uniqueId]: {
          ...selectedCriterias[groupName][model.key].criteriaValues[uniqueId],
          values: (() => {
            let result = [
              ...selectedCriterias[groupName][model.key].criteriaValues[uniqueId].values,
            ];
            const valsByKey = newValue.reduce(
              (m, val) => ({
                ...m,
                [val.key]: m[val.key]
                  ? [...m[val.key], JSON.parse(val.value).id]
                  : [JSON.parse(val.value).id],
              }),
              {}
            );
            const newValueFieldKeys = Object.keys(valsByKey);

            const fieldKeys = model.descriptors.map(d => d.key);
            const clearedKeys = fieldKeys.filter(fk => newValueFieldKeys.indexOf(fk) === -1);

            clearedKeys.forEach(fKey => {
              result = result.filter(fkVal => fkVal.key !== fKey);
            });
            newValueFieldKeys.forEach(k => {
              const isNewKey = !result.find(v => v.key === k);
              if (isNewKey) {
                const descriptor = model.descriptors.find(d => d.key === k);
                result.push({
                  key: descriptor.key,
                  values: valsByKey[k],
                  java: descriptor.javaParameter,
                  typeId: ParameterType[descriptor.type],
                  operatorId:
                    selectedCriterias[groupName][model.key].criteriaValues[uniqueId].operatorId,
                });
              } else {
                result = result.map(fkVal =>
                  fkVal.key === k
                    ? {
                        ...fkVal,
                        values: valsByKey[k],
                      }
                    : fkVal
                );
              }
            });
            return result;
          })(),
        },
      },
    },
  },
});

const changeIntegerValue = ({ newValue, uniqueId, model, groupName }, selectedCriterias) =>
  changeStringValue({ newValue, uniqueId, model, groupName }, selectedCriterias);

const changeEnumValue = ({ newValue, uniqueId, model, groupName }, selectedCriterias) => ({
  ...selectedCriterias,
  [groupName]: {
    ...selectedCriterias[groupName],
    [model.key]: {
      ...selectedCriterias[groupName][model.key],
      criteriaValues: {
        ...selectedCriterias[groupName][model.key].criteriaValues,
        [uniqueId]: {
          ...selectedCriterias[groupName][model.key].criteriaValues[uniqueId],
          values: newValue,
        },
      },
    },
  },
});

const changeCriteriaValue = (
  { newValue, uniqueId, model, groupName, parent },
  selectedCriterias
) => {
  if (parent)
    return {
      ...selectedCriterias,
      [groupName]: {
        ...selectedCriterias[groupName],
        [parent.model.key]: {
          ...selectedCriterias[groupName][parent.model.key],
          criteriaValues: changeCriteriaValue(
            { newValue, uniqueId, model, groupName: parent.uniqueId },
            selectedCriterias[groupName][parent.model.key].criteriaValues
          ),
        },
      },
    };
  switch (model.type) {
    case 'INTEGER':
      return changeIntegerValue({ newValue, uniqueId, model, groupName }, selectedCriterias);
    case 'ENUM':
    case 'ENUM_TEXT':
    case 'FOREIGN_KEY':
    case 'N_FOREIGN_KEY':
    case 'IFOREIGN_KEY':
    case 'TFOREIGN_KEY':
      return changeEnumValue({ newValue, uniqueId, model, groupName }, selectedCriterias);
    case 'DATE':
      return changeDateValue({ newValue, uniqueId, model, groupName }, selectedCriterias);
    case 'CLUBS':
      return changeClubsValue({ newValue, uniqueId, model, groupName }, selectedCriterias);

    default:
      return changeIntegerValue({ newValue, uniqueId, model, groupName }, selectedCriterias);
  }
};

const changeOperatorValue = (
  { operatorValue, uniqueId, model, groupName, parent },
  selectedCriterias
) => {
  if (parent) {
    return {
      ...selectedCriterias,
      [groupName]: {
        ...selectedCriterias[groupName],
        [parent.model.key]: {
          ...selectedCriterias[groupName][parent.model.key],
          criteriaValues: changeOperatorValue(
            {
              operatorValue,
              uniqueId,
              model,
              groupName: parent.uniqueId,
              parent: parent.parentDescriptor,
            },
            selectedCriterias[groupName][parent.model.key].criteriaValues
          ),
        },
      },
    };
  }
  return {
    ...selectedCriterias,
    [groupName]: {
      ...selectedCriterias[groupName],
      [model.key]: {
        ...selectedCriterias[groupName][model.key],
        criteriaValues: {
          ...selectedCriterias[groupName][model.key].criteriaValues,
          [uniqueId]:
            [
              OperatorType.NOT_NULL,
              OperatorType.NULL,
              OperatorType.FALSE,
              OperatorType.TRUE,
            ].indexOf(operatorValue) !== -1
              ? {
                  ...selectedCriterias[groupName][model.key].criteriaValues[uniqueId],
                  operatorId: operatorValue,
                  values:
                    [OperatorType.NOT_NULL, OperatorType.NULL].indexOf(operatorValue) !== -1
                      ? []
                      : (operatorValue === OperatorType.TRUE && ['true']) || ['false'],
                }
              : {
                  ...selectedCriterias[groupName][model.key].criteriaValues[uniqueId],
                  operatorId: operatorValue,
                  values:
                    model.type === 'CLUBS'
                      ? selectedCriterias[groupName][model.key].criteriaValues[uniqueId].values.map(
                          v => ({
                            ...v,
                            operatorId: operatorValue,
                          })
                        )
                      : selectedCriterias[groupName][model.key].criteriaValues[uniqueId].values,
                },
        },
      },
    },
  };
};

const removeGroupedMandatoryCriteria = (
  { model, groupName },
  selectedCriterias,
  removeCriteria
) => {
  const selectedGroup = selectedCriterias[groupName];
  const parentCriteriaKey = model.parentDescriptor.key;
  const criteriasToRemove = Object.values(selectedGroup).reduce((acc, criteriaEntry) => {
    if (
      criteriaEntry.model.parentDescriptor &&
      criteriaEntry.model.parentDescriptor.key === parentCriteriaKey
    ) {
      return [
        ...acc,
        ...Object.keys(criteriaEntry.criteriaValues).reduce(
          (acc2, uId) => [...acc2, { uniqueId: uId, model: criteriaEntry.model, groupName }],
          []
        ),
      ];
    }
    return acc;
  }, []);

  return criteriasToRemove.reduce(
    (currentResult, ctr) => removeCriteria(ctr, currentResult, true),
    selectedCriterias
  );
};

const removeCriteria = (
  { uniqueId, model, groupName, parent },
  selectedCriterias,
  ignoreGroupTypeConfig
) => {
  if (parent) {
    const withRemovedCriteria = removeCriteria(
      {
        uniqueId,
        model,
        groupName: parent.uniqueId,
        parent: parent.model.parentDescriptor,
      },
      selectedCriterias[groupName][parent.model.key].criteriaValues
    );

    if (!Object.keys(withRemovedCriteria).length) {
      const groupTypeHasValues = !!Object.keys(selectedCriterias[groupName]).filter(
        k => k !== parent.model.key
      ).length;

      if (groupTypeHasValues) {
        return {
          ...selectedCriterias,
          [groupName]: Object.entries(selectedCriterias[groupName]).reduce((r, [k, v]) => {
            if (k === parent.model.key) {
              return r;
            }
            return { ...r, [k]: v };
          }, {}),
        };
      }

      return Object.entries(selectedCriterias).reduce((r, [k, v]) => {
        if (k === groupName) {
          return r;
        }
        return { ...r, [k]: v };
      }, {});
    }

    return {
      ...selectedCriterias,
      [groupName]: {
        ...selectedCriterias[groupName],
        [parent.model.key]: {
          ...selectedCriterias[groupName][parent.model.key],
          criteriaValues: withRemovedCriteria,
        },
      },
    };
  }
  if (model.mandatory && !ignoreGroupTypeConfig && model.parentDescriptor) {
    const isUnique =
      Object.keys(selectedCriterias[groupName][model.key].criteriaValues).length <= 1;

    if (isUnique) {
      return removeGroupedMandatoryCriteria(
        { model, groupName },
        selectedCriterias,
        removeCriteria
      );
    }
  }

  const criteriaName = model.key;
  let removeCriteriaEntryInGroup = false;
  let removeGroup = false;
  if (Object.keys(selectedCriterias[groupName][criteriaName].criteriaValues).length === 1)
    removeCriteriaEntryInGroup = true;

  if (removeCriteriaEntryInGroup && Object.keys(selectedCriterias[groupName]).length === 1) {
    removeGroup = true;
  }

  if (removeGroup) {
    const removeCriteriaGroup = () =>
      Object.entries(selectedCriterias).reduce((acc, [currentGroupName, value]) => {
        if (currentGroupName !== groupName) return { ...acc, [currentGroupName]: value };
        return acc;
      }, {});
    return removeCriteriaGroup();
  }

  if (removeCriteriaEntryInGroup) {
    const doRemoveCriteriaEntryInGroup = () =>
      Object.entries(selectedCriterias[groupName]).reduce((acc, [currentCriteriaName, value]) => {
        if (currentCriteriaName === criteriaName) return acc;
        return { ...acc, [currentCriteriaName]: value };
      }, {});
    return {
      ...selectedCriterias,
      [groupName]: doRemoveCriteriaEntryInGroup(),
    };
  }

  const removeCriteriaSingleValue = () =>
    Object.entries(selectedCriterias[groupName][criteriaName].criteriaValues).reduce(
      (acc, [id, value]) => {
        if (id === uniqueId) return acc;
        return { ...acc, [id]: value };
      },
      {}
    );

  return {
    ...selectedCriterias,
    [groupName]: {
      ...selectedCriterias[groupName],
      [criteriaName]: {
        ...selectedCriterias[groupName][criteriaName],
        criteriaValues: removeCriteriaSingleValue(),
      },
    },
  };
};

const mapAvailableResultfields = rfList =>
  Object.entries(rfList).reduce(
    (acc, [groupKey, group]) => ({
      ...acc,
      [groupKey]: Object.entries(group).map(([fieldKey, type]) => ({
        label: utils.getLang(`smartmessaging.resultfield.${fieldKey}`),
        value: fieldKey,
        type,
      })),
    }),
    {}
  );

const getSetup = massActionState => {
  const { selectedCriterias, selectedResultfields, currentSetupId } = massActionState;

  function proceedSingleField(model, field) {
    return {
      key: model.key,

      values: field.values,
      java: !!model.java,
      typeId: ParameterType[model.type],
      operatorId: field.operatorId,
    };
  }

  function proceedGroupedCriterias() {
    const groupedCriterias = Object.values(selectedCriterias).reduce(
      (all, groupTypeCriterias) =>
        all.concat(
          Object.values(groupTypeCriterias).filter(
            c => ParameterType[c.model.type] === ParameterType.GROUP
          )
        ),
      []
    );

    return groupedCriterias.reduce(
      (listOfGroupParamValues, valuesForGroupKey) =>
        listOfGroupParamValues.concat(
          Object.values(valuesForGroupKey.criteriaValues).reduce(
            (listOfGroupParamValuesForKey, groupSubValues) =>
              listOfGroupParamValuesForKey.concat({
                key: valuesForGroupKey.model.key,
                values: Object.values(groupSubValues).reduce(
                  (subValueList, groupSubValue) =>
                    subValueList.concat(
                      Object.values(groupSubValue.criteriaValues).reduce(
                        (listOfParamValues, field) =>
                          listOfParamValues.concat(proceedSingleField(groupSubValue.model, field)),
                        []
                      )
                    ),
                  []
                ),
                javaParameter: !!valuesForGroupKey.model.javaParameter,
                typeId: ParameterType[valuesForGroupKey.model.type],
                operatorId: null,
              }),
            []
          )
        ),
      []
    );
  }

  function proceedSelectedCriterias() {
    const allSingleValues = Object.values(selectedCriterias).reduce(
      (acc, groupTypeValues) =>
        [...acc].concat(
          Object.values(groupTypeValues).filter(
            criteriaValue => ParameterType[criteriaValue.model.type] !== ParameterType.GROUP
          )
        ),
      []
    );

    return allSingleValues.reduce(
      (
        acc2,
        criteriaValue //  { model: {}, criteriaValues: { uuid: { operatorId: 5, values: [] } } },
      ) =>
        [...acc2].concat(
          Object.values(criteriaValue.criteriaValues).reduce(
            (acc3, singleValue) =>
              [...acc3].concat(proceedSingleField(criteriaValue.model, singleValue)),
            []
          )
        ),
      []
    );
  }

  function proceedSelectedResultfields() {
    const selectedResultfieldsGroups = Object.values(selectedResultfields);
    return Object.values(selectedResultfieldsGroups).reduce(
      (acc, resultFieldList) =>
        [...acc].concat(resultFieldList.map(resultfield => resultfield.value)),
      []
    );
  }

  return {
    id: currentSetupId,
    value: JSON.stringify(proceedSelectedCriterias().concat(proceedGroupedCriterias())),
    columnList: JSON.stringify(proceedSelectedResultfields()),
  };
};

const getRecipe = massActionState => {
  const { currentSetupId, currentRecipeId, currentName, requestModel } = massActionState;
  return {
    id: currentRecipeId,
    name: currentName,
    setupId: currentSetupId,
    requestModelTypeId: requestModel.requestModelTypeId,
  };
};

const getMassActionCampaign = massActionState => {
  const {
    currentMassActionId,
    currentRecipeId,
    currentName,
    currentCreationDate,
    currentByPass,
    currentReplay,
  } = massActionState;
  return {
    id: currentMassActionId,
    recipeId: currentRecipeId,
    name: currentName,
    creationDate: currentCreationDate || new Date(),
    enabled: true,
    replay: currentReplay,
    byPass: currentByPass,
  };
};

const getContentValidity = ({ object, content, actionType, min }) => {
  switch (actionType) {
    case ActionType.EMAIL.id:
    case ActionType.EMAIL_COACH.id:
    case ActionType.MEMBER_NOTIFICATION.id:
    case ActionType.EMAIL_SPONSORSHIP.id:
    case ActionType.EMAIL_INVITATION.id: {
      return contentEditorUtils.checkMailContentValidity({ object, content });
    }
    case ActionType.SMS.id:
      return smsUtils.checkSMSContentValidity(content, min);
    case ActionType.PUSH_XPLORACTIVE.id: {
      return contentEditorUtils.checkXplorActiveNotifContentValidity({ object, content });
    }
    default:
      return { isValid: true, invalidities: [] };
  }
};

const ungroupSelectedCriterias = groupedSelection =>
  Object.values(groupedSelection).reduce(
    (ungroupedForAllGroupTypes, criteriaGroup) =>
      [...ungroupedForAllGroupTypes].concat(
        Object.values(criteriaGroup).reduce(
          (ungroupedForGroupType, singleCriteriaObject) =>
            [...ungroupedForGroupType].concat(
              Object.entries(singleCriteriaObject.criteriaValues).map(([uniqueId, criteriaValue]) =>
                ParameterType[singleCriteriaObject.model.type] === ParameterType.GROUP
                  ? {
                      model: singleCriteriaObject.model,
                      values: ungroupSelectedCriterias({ criteriaValue }),
                      uniqueId,
                    }
                  : {
                      ...criteriaValue,
                      model: singleCriteriaObject.model,
                      uniqueId,
                    }
              )
            ),
          []
        )
      ),
    []
  );

const criteriaValidity = (criteria, selection) => {
  const twinCriterias = selection.filter(pCrit => pCrit.model.key === criteria.model.key);

  switch (ParameterType[criteria.model.type]) {
    case ParameterType.GROUP: {
      return criteria.values.reduce(
        (groupValidity, subCriteria) => {
          const subValidity = criteriaValidity(subCriteria, criteria.values);
          return {
            isValid: groupValidity.isValid && subValidity.isValid,
            messages: groupValidity.messages.concat(subValidity.messages),
            validities: { ...groupValidity.validities, [subCriteria.uniqueId]: subValidity },
          };
        },
        { isValid: true, messages: [], validities: {} }
      );
    }
    default: {
      const validity = { isValid: true, messages: [] };
      if (criteria.model.mandatory && twinCriterias.length > 1)
        validity.messages.push(utils.getLang('smartmessaging.massAction.tooltip.mandatoryFilter'));
      const usedOperators = twinCriterias
        .map(rCrit => rCrit.operatorId)
        .filter(opId => opId === 0 || !!opId);
      const opCount = usedOperators.reduce(
        (count, op) => (op !== criteria.operatorId ? count : count + 1),
        0
      );
      if (opCount > 1) {
        validity.isValid = false;
        validity.messages.push(
          utils.getLang('smartmessaging.massAction.tooltip.duplicateOperator')
        );
      }

      switch (criteria.operatorId) {
        case OperatorType.NULL:
        case OperatorType.NOT_NULL:
          validity.isValid = validity.isValid && true;
          break;
        case OperatorType.BETWEEN:
        case OperatorType.NOT_BETWEEN:
          if (criteria.values.length < 2 || !criteria.values[0] || !criteria.values[1]) {
            validity.isValid = false;
            validity.messages.push(
              utils.getLang('smartmessaging.massAction.tooltip.noEmptyFilter')
            );
          }
          break;
        default:
          if (
            criteria.operatorId === undefined ||
            criteria.operatorId === null ||
            criteria.operatorId === ''
          ) {
            validity.isValid = false;
            validity.messages.push(
              utils.getLang('smartmessaging.massAction.tooltip.requiredOperator')
            );
          }
          if (!criteria.values[0]) {
            validity.isValid = false;
            validity.messages.push(
              utils.getLang('smartmessaging.massAction.tooltip.noEmptyFilter')
            );
          }
          break;
      }
      return validity;
    }
  }
};

const selectionIsValid = selection => {
  if (!selection) return { isValid: false, validities: {} };

  const validity = { isValid: true, validities: {} };
  const ungroupedCriterias = ungroupSelectedCriterias(selection);

  ungroupedCriterias.forEach(crit => {
    const critValidity = criteriaValidity(crit, ungroupedCriterias);
    if (!critValidity.isValid) {
      validity.isValid = false;
    }
    validity.validities[crit.uniqueId] = critValidity;
  });
  return validity;
};

export default {
  addSelectedCriteria,
  addAllMandatories,
  changeCriteriaValue,
  changeOperatorValue,
  getContentValidity,
  mapAvailableCriterias,
  mapAvailableResultfields,
  mapCriteriasByKey,
  mapMandatoryCriterias,
  removeCriteria,
  getSetup,
  getRecipe,
  getMassActionCampaign,
  getSelectedCriteriasFromValue,
  getSelectedColumnsFromList,
  selectionIsValid,
};
