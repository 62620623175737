import { useRef, useState } from 'react';
import useDidMount from './useDidMount';
import CKEditor from 'src/components/common/ReactCKEditor';

export default function useRenderEditor(onCKEditorDialogDefintion = true) {
  const container = useRef();
  const [rendered, setRendered] = useState(false);

  useDidMount(() => {
    if (onCKEditorDialogDefintion === true) CKEditor.onCKEditorDialogDefintion();
    setRendered(true);
  });

  return { container, rendered };
}
