import React from 'react';
import CKEditor from '../ReactCKEditor';
import { CKEditorEvent } from 'src/types/types';

interface MemberNotificationContentEditorProps {
  events?: { [key: string]: (ckEvent: CKEditorEvent) => void };
  config?: { [key: string]: unknown };
  content?: string;
  name?: string;
  container: React.MutableRefObject<HTMLDivElement>;
}

const MemberNotificationContentEditor = ({
  name = 'htmlEditor',
  container,
  events = {},
  config = {},
  content = null,
}: MemberNotificationContentEditorProps) =>
  Boolean(container.current) && (
    <CKEditor
      name={name}
      content={content}
      config={{
        removeButtons:
          'Underline,Subscript,Superscript,Anchor,Image,Flash,Table,About,Copy,Paste,PasteText,PasteFromWord,Cut,Scayt',
        extraPlugins: 'strinsert,uploadimage',
        removePlugins: 'elementspath',
        autoParagraph: false,
        enterMode: 3,
        height: 70 * (container.current.clientHeight / 100),
        protectedSource: /<!\[CDATA\[[\s\S]*?\]\]>/g,
        ...config,
      }}
      events={{
        pluginsLoaded(event) {
          event.editor.dataProcessor.dataFilter.addRules({
            elements: {
              script() {
                return false;
              },
            },
          });
        },
        ...events,
      }}
    />
  );

export default MemberNotificationContentEditor;
