import React from 'react';
import XplorActiveNotifyObjectEditor from 'src/components/common/contentEditor/XplorActiveNotifyObjectEditor';
import XplorActiveNotifyContentEditor from 'src/components/common/contentEditor/XplorActiveNotifyContentEditor';
import useRenderEditor from 'src/hooks/useRenderEditor';
import EmailObjectLabel from './EmailObjectLabel';
import utils from 'src/utils/utils';
import CtnLang from './CtnLang';
import EmailContentLabel from './EmailContentLabel';
import { CKEditorEvent, ProcessedFieldType } from 'src/types/types';

interface MassActionXplNotifyEditorProps {
  isWritable: boolean;
  fields: {
    fields: {
      contentFields: ProcessedFieldType;
      objectFields: ProcessedFieldType;
      linkFields: ProcessedFieldType;
    };
  };
  content?: string;
  object?: string;
  onEditorLoaded: (cke: CKEditorEvent['editor']) => void;
  onObjectEditorLoaded: (cke: CKEditorEvent['editor']) => void;
  onChange: (e: CKEditorEvent) => void;
}

const MassActionXplNotifyEditor = ({
  fields,
  content = '',
  object = '',
  onEditorLoaded,
  onObjectEditorLoaded,
  onChange,
  isWritable,
}: MassActionXplNotifyEditorProps) => {
  const { container, rendered } = useRenderEditor();

  return (
    <div
      style={{
        height: '70%',
        minHeight: '600px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        overflow: 'visible',
      }}
      ref={container}
    >
      {rendered && (
        <>
          <div>
            <div style={{ width: '100%', display: 'flex' }}>
              <EmailObjectLabel
                title={utils.getLang('smartmessaging.contentEditor.xploractivepush.object')}
              />
              <div style={{ marginRight: 0, marginLeft: 'auto' }}>
                <CtnLang />
              </div>
            </div>
            <XplorActiveNotifyObjectEditor
              config={{
                readOnly: !isWritable,
                fields: fields.fields.objectFields,
              }}
              events={{
                loaded: event => {
                  onObjectEditorLoaded(event.editor);
                },
                change: onChange,
              }}
              object={object}
            />
          </div>
          <div>
            <EmailContentLabel
              skipOptoutControl
              title={utils.getLang('smartmessaging.contentEditor.xploractivepush.content')}
            />
            <XplorActiveNotifyContentEditor
              content={content}
              config={{
                readOnly: !isWritable,
                fields: fields.fields.contentFields,
              }}
              events={{
                loaded: event => {
                  onEditorLoaded(event.editor);
                },
                change: onChange,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default MassActionXplNotifyEditor;
