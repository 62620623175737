import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import Utils from 'src/utils/utils';
import NotifyContentLabel from 'src/components/common/contentEditor/NotifyContentLabel';
import { checkContentValidity } from 'src/reducers/campaignEditor';
import CtnLang from './CtnLang';
import EmailObjectEditor from 'src/components/common/contentEditor/EmailObjectEditor';
import MemberNotificationContentEditor from 'src/components/common/contentEditor/MemberNotificationContentEditor';
import useRenderEditor from 'src/hooks/useRenderEditor';

const ConnectedNotifyLabel = connect(
  state => ({
    contentValidity: checkContentValidity(state),
  }),
  () => ({})
)(NotifyContentLabel);

const NotifyEditorRender = ({
  currentAction,
  fields,
  onNotifyObjectChange,
  onNotifyContentChange,
  onNotifyEditorLoaded,
  isWritable,
}) => {
  const { container, rendered } = useRenderEditor();

  return (
    <div style={{ height: '85%' }} ref={container}>
      {rendered && (
        <>
          <div style={{ width: '100%', display: 'flex' }}>
            <Typography
              color="textSecondary"
              style={{ margin: '5px 10px' }}
              variant="subtitle2"
              noWrap
            >
              {Utils.getLang('smartmessaging.contentEditor.notify.object')}
            </Typography>
            <div style={{ marginRight: 0, marginLeft: 'auto' }}>
              <CtnLang />
            </div>
          </div>
          <EmailObjectEditor
            container={container}
            config={{
              readOnly:
                !!currentAction.content.object && currentAction.messageModel
                  ? !isWritable || !currentAction.messageModel.generic
                  : !isWritable ||
                    (currentAction.customTemplate && !currentAction.customTemplate.generic),
              fields: fields.fields.objectFields,
            }}
            events={{
              change: event => {
                onNotifyObjectChange(event.editor.getData());
              },
            }}
            object={currentAction.content.object}
          />
          <ConnectedNotifyLabel />
          <MemberNotificationContentEditor
            container={container}
            config={{
              readOnly: currentAction.messageModel
                ? !isWritable || !currentAction.messageModel.generic
                : !isWritable ||
                  (currentAction.customTemplate && !currentAction.customTemplate.generic),
              fields: fields.fields.contentFields,
            }}
            events={{
              change: event => {
                onNotifyContentChange(event.editor.getData());
              },
              loaded: ({ editor }) => {
                onNotifyEditorLoaded(editor);
              },
            }}
            content={currentAction.content.content || ''}
          />
        </>
      )}
    </div>
  );
};

NotifyEditorRender.propTypes = {
  currentAction: PropTypes.object,
  fields: PropTypes.any.isRequired,
  onNotifyObjectChange: PropTypes.func.isRequired,
  onNotifyContentChange: PropTypes.func.isRequired,
  onNotifyEditorLoaded: PropTypes.func.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

NotifyEditorRender.defaultProps = {
  currentAction: null,
};

const mapStateToProps = state => ({
  currentAction: state.campaignEditor.actions.currentAction,
});

const actionCreators = {
  onNotifyObjectChange: data => ({ type: 'ON_NOTIFY_OBJECT_CHANGE', value: data }),
  onNotifyContentChange: data => ({ type: 'ON_NOTIFY_CONTENT_CHANGE', value: data }),
  onNotifyEditorLoaded: editor => ({ type: 'ON_NOTIFY_EDITOR_LOADED', value: editor }),
};

export default connect(mapStateToProps, actionCreators)(NotifyEditorRender);
