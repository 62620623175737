import { createSelector } from 'reselect';
import ActionTypes from 'src/enums/actionType';

const getSelectedCriterias = state => state.selectedCriterias;
const getSelectedCriteriaGroup = (state, groupName) => state.selectedCriterias[groupName];
const getCurrentMassActionId = state => state.currentMassActionId;
const getContentFields = state => {
  const selectedFields = Object.values(state.massAction.selectedResultfields).reduce(
    (acc, currentFieldGroup) => ({
      ...acc,
      ...currentFieldGroup.reduce(
        (acc2, currentField) => ({ ...acc2, [currentField.value]: currentField.type }),
        {}
      ),
    }),
    {}
  );
  const { defaultContentFields, forbiddenContentFields } = state.massAction;
  const allFields = { ...selectedFields, ...defaultContentFields }; // filter by removing forbidden fields
  return Object.keys(allFields).reduce(
    (r, fKey) =>
      forbiddenContentFields.indexOf(fKey) === -1 ? { ...r, [fKey]: allFields[fKey] } : r,
    {}
  );
};

const getUngroupedSelectedColumns = state =>
  Object.values(state.massAction.selectedResultfields).reduce(
    (ungrouped, current) => [...ungrouped, ...current.map(c => c.value)],
    []
  );

const getUngroupedAvailaibleFields = state =>
  Object.values(state.massAction.availableResultfields).reduce(
    (ungrouped, current) => ({
      ...ungrouped,
      ...current.reduce((r, c) => ({ ...r, [c.value]: c.type }), {}),
    }),
    {}
  );

const isIdColumnSelected = state => {
  const groups = Object.values(state.massAction.selectedResultfields);
  const fields = groups.reduce(
    (acc, group) => [...acc, ...Object.values(group).map(f => f.value)],
    []
  );

  return fields.indexOf('field0') !== -1;
};

const nameIsValid = state => !!state.massAction.currentName;

const getAvailableResultfields = state => state.massAction.availableResultfields;

const getContactIdResultfield = state =>
  state.massAction.availableResultfields.contact.filter(({ value }) => value === 'field0')[0];

const isNewContent = ({ massAction }) =>
  !massAction.currentCampaignAction?.id || massAction.forceIsNewContent;

const getDataLoadingChangeEventHandled = ({ massAction }) => {
  if (!massAction.currentCampaignAction) return true;
  switch (massAction.currentCampaignAction.actionTypeId) {
    case ActionTypes.EMAIL.id:
      return massAction.dataLoadingChangeEventHandled >= 2;
    case ActionTypes.SMS.id:
      return massAction.dataLoadingChangeEventHandled >= 1;
    default:
      return true;
  }
};

export const massActionSelector = id =>
  createSelector(
    [state => state.massAction.massActions],
    massActionList => massActionList.filter(massAction => massAction.id === id)[0]
  );

export default {
  getAvailableResultfields,
  getContactIdResultfield,
  getSelectedCriterias,
  getSelectedCriteriaGroup,
  getUngroupedSelectedColumns,
  getUngroupedAvailaibleFields,
  getCurrentMassActionId,
  getContentFields,
  getDataLoadingChangeEventHandled,
  isIdColumnSelected,
  nameIsValid,
  isNewContent,
};
