import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentRequestModelId } from 'src/reducers/campaignEditor';

import CtnLang from './CtnLang';
import SmsEditor from 'src/components/common/contentEditor/SmsEditor';

const SmsEditorRender = ({ currentAction, fields, onSmsContentChange, isWritable }) => (
  <div
    style={{
      height: '85%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      flex: 1,
      overflow: 'visible',
    }}
  >
    <div style={{ marginRight: 0, marginLeft: 'auto' }}>
      <CtnLang />
    </div>
    <div>
      <SmsEditor
        config={{
          readOnly: currentAction.messageModel
            ? !isWritable || !currentAction.messageModel.generic
            : !isWritable ||
              (currentAction.customTemplate && !currentAction.customTemplate.generic),
          fields: fields.fields,
        }}
        events={{
          change: event => {
            const data = event.editor.getData();
            onSmsContentChange(data);
          },
          instanceReady: event => {
            if (currentAction.content) event.editor.setData(currentAction.content);
          },
        }}
      />
    </div>
  </div>
);

const mapStateToProps = state => ({
  currentAction: state.campaignEditor.actions.currentAction,
  currentRequestModelId: getCurrentRequestModelId(state),
});

const actionCreators = {
  onSmsContentChange: data => ({ type: 'ON_SMS_CONTENT_CHANGE', value: data }),
};

SmsEditorRender.propTypes = {
  currentAction: PropTypes.object,
  fields: PropTypes.any.isRequired,
  isWritable: PropTypes.bool.isRequired,
  onSmsContentChange: PropTypes.func.isRequired,
};

SmsEditorRender.defaultProps = {
  currentAction: null,
};

export default connect(mapStateToProps, actionCreators)(SmsEditorRender);
