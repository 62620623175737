import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';
import { connect } from 'react-redux';
import { useAlert } from 'src/hooks';
import {
  DialogContent,
  Dialog,
  AppBar,
  Toolbar,
  Button,
  Typography,
  IconButton,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ActionType from 'src/enums/actionType';

import SmsEditor from 'src/components/common/contentEditor/SmsEditor';
import utils from 'src/utils/utils';
import contentEditorUtils from 'src/components/common/contentEditor/utils/contentEditorUtils';

import { saveContent } from 'src/reducers/customTemplates';
import EmailEditor from './EmailEditor';

const useStyles = makeStyles()(theme => ({
  saveBtn: {
    margin: theme.spacing(0.5),
  },
  deleteBtn: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.warning.light, theme.palette.action.hoverOpacity),
    },
  },
  buttonMargin: { margin: theme.spacing(0.5) },
  appBar: {
    position: 'relative',
  },
  content: { padding: theme.spacing(2), height: '100%' },
  flex: {
    flex: 1,
  },
  paperFullScreen: { width: '97%', height: '97%', borderRadius: '2px' },
  borderTop: { borderTop: theme.border.primary },
}));

const CustomContentEditor = ({
  editorIsVisible,
  template,
  closeEditor,
  data,
  doSaveContent,
  onMailEditorLoaded,
  onObjectEditorLoaded,
}) => {
  const { classes } = useStyles();
  const { showAlert } = useAlert();
  return (
    <Dialog
      classes={{ paperFullScreen: classes.paperFullScreen }}
      fullScreen={!!template && template.actionTypeId === ActionType.EMAIL.id}
      open={editorIsVisible}
      disableEnforceFocus
    >
      {editorIsVisible && [
        <AppBar className={classes.appBar} color="primary" key={0}>
          <Toolbar>
            <Typography variant="h5" color="inherit" className={classes.flex}>
              {utils.getLang(`smartmessaging.contentEditor.title.${template.actionTypeId}`)}
            </Typography>

            <IconButton
              aria-label="clear"
              onClick={e => {
                e.preventDefault();
                closeEditor();
              }}
              color="inherit"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>,
        <DialogContent style={{ height: '100%', overflowY: 'hidden', padding: 0 }} key={1}>
          <div className={classes.content}>
            {template.actionTypeId === ActionType.EMAIL.id && (
              <EmailEditor
                onObjectEditorLoaded={onObjectEditorLoaded}
                onEditorLoaded={onMailEditorLoaded}
                onChange={() => {}}
                fields={data.fields}
                content={contentEditorUtils.freemarkerToHtml(
                  data.content.body || '',
                  data.fields.fieldsModels,
                  data.params
                )}
                object={contentEditorUtils.freemarkerToHtml(
                  template.subject || '',
                  data.fields.fieldsModels,
                  data.params
                )}
                readOnly={!template.isWritable}
              />
            )}
            {template.actionTypeId === ActionType.SMS.id && (
              <SmsEditor
                content={contentEditorUtils.smsFreemarkerToHtml(
                  data.content.body || '',
                  data.fields.fieldsModels,
                  data.params
                )}
                config={{ fields: data.fields.fields, readOnly: !template.isWritable }}
                events={{
                  change: () => {},
                  loaded: event => {
                    onMailEditorLoaded(event.editor);
                  },
                }}
              />
            )}
          </div>
        </DialogContent>,
        <DialogActions className={classes.borderTop} key={2}>
          <Button
            variant="text"
            color="primary"
            onClick={e => {
              e.preventDefault();
              closeEditor();
            }}
          >
            {utils.getLang('smartmessaging.button.contentEditor.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.saveBtn}`}
            onClick={e => {
              e.preventDefault();
              doSaveContent(template, showAlert);
            }}
            disabled={!template.isWritable}
          >
            {utils.getLang('smartmessaging.button.contentEditor.save')}
          </Button>
        </DialogActions>,
      ]}
    </Dialog>
  );
};

const mapStateToProps = state => ({
  editorIsVisible: state.customTemplates.showEditor,
});

const actionCreators = {
  closeEditor: () => ({ type: 'SHOW_CUSTOM_CONTENT_EDITOR', value: false }),
  onMailEditorLoaded: mailEditor => ({ type: 'ON_CUSTOM_MAIL_EDITOR_LOADED', value: mailEditor }),
  onObjectEditorLoaded: objectEditor => ({
    type: 'ON_CUSTOM_OBJECT_EDITOR_LOADED',
    value: objectEditor,
  }),
  doSaveContent: saveContent,
};

CustomContentEditor.propTypes = {
  editorIsVisible: PropTypes.bool.isRequired,
  closeEditor: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  doSaveContent: PropTypes.func.isRequired,
  onMailEditorLoaded: PropTypes.func.isRequired,
  onObjectEditorLoaded: PropTypes.func,
};

CustomContentEditor.defaultProps = {
  onObjectEditorLoaded: undefined,
};

export default connect(mapStateToProps, actionCreators)(CustomContentEditor);
