// FIXME add routes with steps
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  CampaignTarget,
  CampaignInfos,
  CampaignMessage,
  EditorAppBar,
  CampaignSchedule,
} from './editorComponents';
import withSteps from 'src/components/common/hocs/withSteps';
import { mapRequestModelByRMTypeId } from 'src/reducers/requestModelList';
import {
  saveCampaign,
  checkCmpInfosValidity,
  checkCampaignValidity,
} from 'src/reducers/campaignEditor';

import { getSortedCategoryList } from 'src/enums/campaignCategory';
import { deleteCampaign } from 'src/reducers/campaignList';
import Loader from 'src/components/common/Loader';
import { useHashLocation } from 'wouter/use-hash-location';
import { useLocation } from 'wouter';
import utils from 'src/utils/utils';

const mapStateToProps = state => ({
  editedCampaign: state.campaignEditor.editedCampaign,
  requestModelByRMTypeId: mapRequestModelByRMTypeId(state),
  recipesById: state.recipeList.recipeListById,
  selectedType: state.campaignEditor.selectedType,
  requestModelsByCategoryId: state.requestModelList.requestModelsByCategoryId,
  sortedCategoryList: getSortedCategoryList(),
  recipeCfgLoaded: state.campaignEditor.recipeFieldsConfig.loaded,
  campaignActions: state.campaignEditor.actions.campaignActions,
  infosValidity: checkCmpInfosValidity(state),
  campaignValidity: checkCampaignValidity(state),
  wizVilTypes: state.app.wizVilleRequestModels,
  wizVilleNewAboTypes: state.app.wizVilleNewAboRequestModels,
  wizVilleCancelAboTypes: state.app.wizVilleCancelAboRequestModels,
  wizVilleEntryActivated: state.app.config.wizVilleEntryActivated,
  wizVilleNewAboActivated: state.app.config.wizVilleNewAboActivated,
  wizVilleCancelAboActivated: state.app.config.wizVilleCancelAboActivated,
  periods: state.campaignEditor.schedule.editedCmpPeriods,
});

const actionCreators = {
  updateEditedCampaign: changes => ({ type: 'UPDATE_EDITED_CAMPAIGN', value: changes }),
  doSaveCampaign: saveCampaign,
  doDeleteCampaign: deleteCampaign,
  selectCampaignType: requestModel => ({
    type: 'SELECT_CAMPAIGN_TYPE',
    value: requestModel,
  }),
  selectOptinType: optinType => ({
    type: 'SELECT_OPTIN_TYPE',
    value: optinType,
  }),
};

export const [INFOS, TARGET, CONTENT, SCHEDULE] = ['infos', 'target', 'content', 'schedule'];

const StepHandler = ({ currentStep, goToStep, showBtns, ...otherProps }) => {
  useEffect(() => {
    if (currentStep !== CONTENT) showBtns(true);
  }, [currentStep, showBtns]);
  switch (currentStep) {
    case INFOS:
      return <CampaignInfos {...otherProps} goToEditorStep={goToStep} />;

    case TARGET:
      return <CampaignTarget {...otherProps} goToEditorStep={goToStep} />;

    case CONTENT:
      return <CampaignMessage {...otherProps} goToEditorStep={goToStep} showBtns={showBtns} />;
    case SCHEDULE:
      return <CampaignSchedule {...otherProps} goToEditorStep={goToStep} />;
    default:
      return <CampaignInfos {...otherProps} goToEditorStep={goToStep} />;
  }
};

StepHandler.propTypes = {
  currentStep: PropTypes.string.isRequired,
  goToStep: PropTypes.func.isRequired,
  showBtns: PropTypes.func.isRequired,
};

function CampaignEditor({
  goToStep,
  doDeleteCampaign,
  doSaveCampaign,
  reduxDataLoaded,
  editedCampaign,
  ...props
}) {
  const [visibleBtns, showBtns] = useState(true);
  const [hLocation, hNavigate] = useHashLocation();
  const [location] = useLocation();

  if (!!editedCampaign?.id && location === '/campaigns/create' && hLocation === '/') {
    hNavigate(`${editedCampaign.id}`);
  }

  if (reduxDataLoaded)
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflow: 'hidden',
          textAlign: 'left',
          height: '100%',
        }}
      >
        <EditorAppBar
          {...props}
          editedCampaign={editedCampaign}
          deleteCampaign={doDeleteCampaign}
          saveCampaign={doSaveCampaign}
          goToEditorStep={goToStep}
          visibleBtns={visibleBtns}
        />
        <StepHandler {...{ ...props, editedCampaign, doSaveCampaign, goToStep, showBtns }} />
      </div>
    );
  return (
    <div>
      <Loader wrapperClass="ghostWrapperClass" text={utils.getLang('smartmessaging.loading')} />
    </div>
  );
}

CampaignEditor.propTypes = {
  goToStep: PropTypes.func.isRequired,
  doDeleteCampaign: PropTypes.func.isRequired,
  doSaveCampaign: PropTypes.func.isRequired,
  reduxDataLoaded: PropTypes.bool.isRequired,
  editedCampaign: PropTypes.object,
};

CampaignEditor.defaultProps = {
  editedCampaign: null,
};

const Stepped = withSteps(CampaignEditor);

export default connect(mapStateToProps, actionCreators)(Stepped);
