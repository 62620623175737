import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, Tooltip, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/Warning';
import SendIcon from '@mui/icons-material/Send';

import PreviousIcon from '@mui/icons-material/ChevronLeft';
import MuiReactSelect from './MuiReactSelect';
import withTestFormController from './hocs/withTestFormController';
import utils from 'src/utils/utils';
import { ActionTypeById } from 'src/enums/actionType';
import FieldKey from 'src/enums/fieldKey';
import { useAlert } from 'src/hooks';

const useStyles = makeStyles()(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
    border: theme.border.primary,
    borderColor: '',
    fontSize: 14,
    maxWidth: 'none',
    paddingRight: '24px',
  },
}));

const StyledTooltip = props => {
  const { classes } = useStyles();
  return <Tooltip classes={classes} {...props} />;
};

const ValidityAdornment = ({ validity }) => (
  <InputAdornment style={{ height: 'unset', margin: '0 4px 0 0' }} position="start">
    {validity.isValid && <DoneIcon color="primary" />}
    {!validity.isValid && (
      <StyledTooltip
        title={
          <ul>
            {validity.messages.map(msg => (
              <li key={msg}>{msg}</li>
            ))}
          </ul>
        }
        placement="bottom"
      >
        <WarningIcon color="error" />
      </StyledTooltip>
    )}
  </InputAdornment>
);

ValidityAdornment.propTypes = { validity: PropTypes.object.isRequired };

const SelectField = ({ options, value, onChange, isMulti, label, validity }) => (
  <MuiReactSelect
    TextFieldProps={{
      label,
      InputLabelProps: {
        shrink: true,
      },
      InputProps: {
        endAdornment: <ValidityAdornment validity={validity} />,
      },
    }}
    closeMenuOnSelect
    onChange={v => onChange(v)}
    value={value ? [value] : []}
    options={options}
    isMulti={isMulti}
  />
);

SelectField.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  validity: PropTypes.object.isRequired,
  isMulti: PropTypes.bool,
};

SelectField.defaultProps = { value: null, isMulti: false };

const TextInputRender = ({ label, onChange, value, validity, InputProps = {}, type = '' }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: 'flex',
        marginTop: '8px',
        marginBottom: '16px',
      }}
    >
      <TextField
        type={type}
        label={label}
        InputLabelProps={{
          shrink: true,
        }}
        style={{
          flex: '1',
        }}
        InputProps={{
          style: {
            fontSize: '0.875rem',
            color: theme.palette.text.secondary,
          },
          endAdornment: <ValidityAdornment validity={validity} />,
          ...InputProps,
        }}
        onChange={event => {
          event.preventDefault();
          onChange(event.target.value);
        }}
        value={value || ''}
      />
    </div>
  );
};

TextInputRender.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validity: PropTypes.object.isRequired,
  InputProps: PropTypes.any,
  type: PropTypes.string,
};

TextInputRender.defaultProps = { value: null, InputProps: null, type: null };

const TestFormInput = ({ onChange, fieldType, label, value, validity, ...otherProps }) => {
  switch (fieldType) {
    case 'IMAGE':
    case 'TABLE': {
      return null;
    }
    case 'INTEGER': {
      return (
        <TextInputRender
          onChange={v => onChange(v)}
          value={value}
          label={label}
          validity={validity}
          type="number"
        />
      );
    }
    default: {
      return (
        <TextInputRender
          onChange={v => onChange(v)}
          value={value}
          label={label}
          validity={validity}
          {...otherProps}
        />
      );
    }
  }
};

TestFormInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  fieldType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  validity: PropTypes.object.isRequired,
};

TestFormInput.defaultProps = { value: null };

const MemoInput = ({ fKey, value, options, ...others }) => {
  const getIngut = useCallback(() => {
    switch (fKey) {
      case 'clubId':
        return (
          <SelectField
            value={value}
            options={options}
            label={utils.getLang('smartmessaging.campaignEditor.testActionForm.clubSender')}
            {...others}
          />
        );
      case FieldKey.CIVILITY:
        return (
          <SelectField
            value={value}
            options={options}
            label={utils.getLang(`smartmessaging.resultfield.${fKey}`)}
            {...others}
          />
        );
      default:
        return <TestFormInput value={value} {...others} />;
    }
  }, [fKey, others, value, options]);

  return useMemo(() => getIngut(), [getIngut]);
};

const TestFormRender = ({
  fields,
  values,
  getOptions,
  back,
  notifyChange,
  formIsValid,
  senderValues,
  submit,
  actionTypeId,
}) => {
  const theme = useTheme();
  const { showAlert } = useAlert();
  return (
    <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 8px',
          zIndex: 1,
          alignItems: 'center',
          background: theme.palette.grey[50],
          boxShadow:
            'rgba(0, 0, 0, 0.2) 0px 0px 0px -1px, rgba(0, 0, 0, 0.14) 0px 0px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
        }}
      >
        {!!back && (
          <IconButton
            style={{
              position: 'relative',
              right: '9px',
            }}
            onClick={e => {
              e.preventDefault();
              back();
            }}
            size="large"
          >
            <PreviousIcon color="" />
          </IconButton>
        )}
        <div style={{ flex: 1 }}>
          <Typography color="textSecondary">
            {`${utils.getLang('smartmessaging.textActionForm.title')} ${utils.getLang(
              `smartmessaging.actionType.${ActionTypeById[actionTypeId]}`
            )}`}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {utils.getLang('smartmessaging.textActionForm.subtitle')}
          </Typography>
        </div>
        <IconButton
          style={{ margin: '2px' }}
          disabled={!formIsValid}
          onClick={e => {
            e.preventDefault();
            submit(() =>
              showAlert({ msg: utils.getLang('smartmessaging.campaignEditor.testAction.success') })
            );
          }}
          size="large"
        >
          <SendIcon style={{ color: theme.palette.text.secondary }} />
        </IconButton>
      </div>
      <div style={{ overflow: 'auto', paddingBottom: '16px', marginBottom: '4px' }}>
        <div style={{ margin: '8px' }}>
          <MemoInput
            options={getOptions('clubId')}
            onChange={v => {
              notifyChange('clubId', v);
            }}
            value={senderValues.clubId.value}
            validity={senderValues.clubId.validity}
            fKey="clubId"
          />
          <MemoInput
            value={senderValues.recipient.value}
            onChange={v => {
              notifyChange('recipient', v);
            }}
            fieldType={actionTypeId === 2 ? 'EMAIL' : 'PHONENUMBER'}
            label={utils.getLang('smartmessaging.campaignEditor.testActionForm.recipient')}
            validity={senderValues.recipient.validity}
            fKey="recipient"
          />
          {Object.keys(fields).map(key => (
            <MemoInput
              key={key}
              fKey={key}
              options={getOptions(key)}
              value={values[key].value}
              onChange={v => notifyChange(key, v)}
              fieldType={fields[key]}
              label={utils.getLang(`smartmessaging.resultfield.${key}`)}
              validity={values[key].validity}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

TestFormRender.propTypes = {
  notifyChange: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  back: PropTypes.func,
  formIsValid: PropTypes.bool.isRequired,
  senderValues: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
  actionTypeId: PropTypes.number.isRequired,
};

TestFormRender.defaultProps = { back: null };

export default withTestFormController(TestFormRender);
