import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import utils from 'src/utils/utils';
import { defaultCkeHtml } from 'src/components/common/ReactCKEditor';
import EmailContentLabel from './EmailContentLabel';
import EmailObjectLabel from './EmailObjectLabel';
import CtnLang from './CtnLang';
import EmailContentEditor from 'src/components/common/contentEditor/EmailContentEditor';
import EmailObjectEditor from 'src/components/common/contentEditor/EmailObjectEditor';
import useRenderEditor from 'src/hooks/useRenderEditor';

const EmailEditorRender = ({
  currentAction,
  fields,
  onMailObjectChange,
  onMailContentChange,
  onMailEditorLoaded,
  isWritable,
}) => {
  const { container, rendered } = useRenderEditor();
  return (
    <div style={{ height: '85%' }} ref={container}>
      {rendered && (
        <>
          <div style={{ width: '100%', display: 'flex' }}>
            <EmailObjectLabel title={utils.getLang('smartmessaging.contentEditor.mail.object')} />
            <div style={{ marginRight: 0, marginLeft: 'auto' }}>
              <CtnLang />
            </div>
          </div>

          <EmailObjectEditor
            container={container}
            config={{
              readOnly:
                !!currentAction.content.object && currentAction.messageModel
                  ? !isWritable || !currentAction.messageModel.generic
                  : !isWritable ||
                    (currentAction.customTemplate && !currentAction.customTemplate.generic),
              fields: fields.fields.objectFields,
            }}
            events={{
              change: event => {
                onMailObjectChange(event.editor.getData());
              },
            }}
            object={currentAction.content.object}
          />

          <EmailContentLabel title={utils.getLang('smartmessaging.contentEditor.mail.content')} />

          <EmailContentEditor
            container={container}
            config={{
              fields: fields.fields.contentFields,
              readOnly: currentAction.messageModel
                ? !isWritable || !currentAction.messageModel.generic
                : !isWritable ||
                  (currentAction.customTemplate && !currentAction.customTemplate.generic),
            }}
            events={{
              change: event => {
                onMailContentChange(event.editor.getData());
              },
              loaded: ({ editor }) => {
                onMailEditorLoaded(editor);
              },
              instanceReady: ({ editor }) => {
                if (currentAction.content.content) editor.setData(currentAction.content.content);
                else editor.setData(defaultCkeHtml);
              },
            }}
          />
        </>
      )}
    </div>
  );
};

EmailEditorRender.propTypes = {
  currentAction: PropTypes.object,
  fields: PropTypes.any.isRequired,
  onMailObjectChange: PropTypes.func.isRequired,
  onMailContentChange: PropTypes.func.isRequired,
  onMailEditorLoaded: PropTypes.func.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

EmailEditorRender.defaultProps = {
  currentAction: null,
};

const mapStateToProps = state => ({
  currentAction: state.campaignEditor.actions.currentAction,
  availableLanguages: state.app.availableLanguages,
});

const actionCreators = {
  onMailObjectChange: data => ({ type: 'ON_MAIL_OBJECT_CHANGE', value: data }),
  onMailContentChange: data => ({ type: 'ON_MAIL_CONTENT_CHANGE', value: data }),
  onMailEditorLoaded: editor => ({ type: 'ON_MAIL_EDITOR_LOADED', value: editor }),
};

export default connect(mapStateToProps, actionCreators)(EmailEditorRender);
