import ActionType from 'src/enums/actionType';
import utils from 'src/utils/utils';
import smsUtils from './smsUtils';

const getWizBlock = () => {
  const wizBlock =
    '<wizblock contenteditable="false">' +
    '       <div class="wiztbl-wrapper"  style="width: 80%;  text-align: center; margin: 5px auto; overflow: visible; padding: 5px; display: block; font-family: sans-serif;">' +
    '            <table width="100%">' +
    '              <tbody>' +
    '                  <tr>' +
    '                      <td style="text-align: left;">' +
    '                          <span>{0}</span>' +
    '                      </td>' +
    '                      <td style="text-align: right;">' +
    '                          <span>{1}</span>' +
    '                      </td>' +
    '                  </tr>' +
    '               </tbody>' +
    '             </table>' +
    '               <table class="wiztbl" width="100%" style="font-weight: bold; color: #ffffff; width: 100%; border-collapse: separate; border-spacing:2px; font-weight: bold;" >' +
    '                 <tbody>' +
    '                   <tr>' +
    '                       <td class="wiztbl-cell" style="border-radius: 5px; width: 9%; height: 45px; min-width: 20px; max-width: 50px; position: relative; background-color: #D32424;">' +
    '                           0' +
    '                           <a href="">' +
    '                               <span class="wizabolink" style="position: absolute; display: block; width: 100%; height: 100%; top: 0; left: 0; z-index: 1;">' +
    '                               </span>' +
    '                           </a>' +
    '                       </td>' +
    '                       <td class="wiztbl-cell" style="border-radius: 5px; width: 9%; height: 45px; min-width: 20px; max-width: 50px; position: relative; background-color: #DA4E1F;">' +
    '                           1' +
    '                           <a href="">' +
    '                               <span class="wizabolink" style="position: absolute; display: block; width: 100%; height: 100%; top: 0; left: 0; z-index: 1;">' +
    '                               </span>' +
    '                           </a>' +
    '                       </td>' +
    '                       <td class="wiztbl-cell" style="border-radius: 5px; width: 9%; height: 45px; min-width: 20px; max-width: 50px; position: relative; background-color: #E2781A;">' +
    '                           2' +
    '                           <a href="">' +
    '                               <span class="wizabolink" style="position: absolute; display: block; width: 100%; height: 100%; top: 0; left: 0; z-index: 1;">' +
    '                               </span>' +
    '                           </a>' +
    '                       </td>' +
    '                       <td class="wiztbl-cell" style="border-radius: 5px; width: 9%; height: 45px; min-width: 20px; max-width: 50px; position: relative; background-color: #EAA215;">' +
    '                           3' +
    '                           <a href="">' +
    '                               <span class="wizabolink" style="position: absolute; display: block; width: 100%; height: 100%; top: 0; left: 0; z-index: 1;">' +
    '                               </span>' +
    '                           </a>' +
    '                       </td>' +
    '                       <td class="wiztbl-cell" style="border-radius: 5px; width: 9%; height: 45px; min-width: 20px; max-width: 50px; position: relative; background-color: #e5c20b;">' +
    '                           4' +
    '                           <a href="">' +
    '                               <span class="wizabolink" style="position: absolute; display: block; width: 100%; height: 100%; top: 0; left: 0; z-index: 1;">' +
    '                               </span>' +
    '                           </a>' +
    '                       </td>' +
    '                       <td class="wiztbl-cell" style="border-radius: 5px; width: 9%; height: 45px; min-width: 20px; max-width: 50px; position: relative; background-color: #F2CD10;">' +
    '                           5' +
    '                           <a href="">' +
    '                               <span class="wizabolink" style="position: absolute; display: block; width: 100%; height: 100%; top: 0; left: 0; z-index: 1;">' +
    '                               </span>' +
    '                           </a>' +
    '                       </td>' +
    '                       <td class="wiztbl-cell" style="border-radius: 5px; width: 9%; height: 45px; min-width: 20px; max-width: 50px; position: relative; background-color: #D5CE2A;">' +
    '                           6' +
    '                           <a href="">' +
    '                               <span class="wizabolink" style="position: absolute; display: block; width: 100%; height: 100%; top: 0; left: 0; z-index: 1;">' +
    '                               </span>' +
    '                           </a>' +
    '                       </td>' +
    '                       <td class="wiztbl-cell" style="border-radius: 5px; width: 9%; height: 45px; min-width: 20px; max-width: 50px; position: relative; background-color: #B8CF45;">' +
    '                           7' +
    '                           <a href="">' +
    '                               <span class="wizabolink" style="position: absolute; display: block; width: 100%; height: 100%; top: 0; left: 0; z-index: 1;">' +
    '                               </span>' +
    '                           </a>' +
    '                       </td>' +
    '                       <td class="wiztbl-cell" style="border-radius: 5px; width: 9%; height: 45px; min-width: 20px; max-width: 50px; position: relative; background-color: #9BD060;">' +
    '                           8' +
    '                           <a href="">' +
    '                               <span class="wizabolink" style="position: absolute; display: block; width: 100%; height: 100%; top: 0; left: 0; z-index: 1;">' +
    '                               </span>' +
    '                           </a>' +
    '                       </td>' +
    '                       <td class="wiztbl-cell" style="border-radius: 5px; width: 9%; height: 45px; min-width: 20px; max-width: 50px; position: relative; background-color: #7FD27B;">' +
    '                           9' +
    '                           <a href="">' +
    '                               <span class="wizabolink" style="position: absolute; display: block; width: 100%; height: 100%; top: 0; left: 0; z-index: 1;">' +
    '                               </span>' +
    '                           </a>' +
    '                       </td>' +
    '                       <td class="wiztbl-cell" style="border-radius: 5px; width: 9%; height: 45px; min-width: 20px; max-width: 50px; position: relative; background-color: #62D395;">' +
    '                           10' +
    '                           <a href="">' +
    '                               <span class="wizabolink" style="position: absolute; display: block; width: 100%; height: 100%; top: 0; left: 0; z-index: 1;">' +
    '                               </span>' +
    '                           </a>' +
    '                       </td>' +
    '                   </tr>' +
    '                 </tbody>' +
    '               </table>' +
    '        </div>' +
    '</wizblock>';

  return utils.stringFormat(wizBlock, [
    utils.getLang('smartmessaging.wizFormLabel.lower'),
    utils.getLang('smartmessaging.wizFormLabel.higher'),
  ]);
};

const getMacroImageValue = (field, clubParams) => {
  const { headerFileId, footerFileId } = clubParams;

  // link
  switch (field) {
    case 'headerImage': {
      const headerSrc = headerFileId ? utils.link(headerFileId) : '/resources/img/pixel.png';
      return `<img src="${headerSrc}" model="${field}" alt="${field}" contenteditable="false" width="600" style="max-width:600px; width: 100%; object-fit: scale-down" />`;
    }
    case 'footerImage': {
      const footerSrc = footerFileId ? utils.link(footerFileId) : '/resources/img/pixel.png';
      return `<img src="${footerSrc}" model="${field}" alt="${field}" contenteditable="false" width="600" style="max-width:600px; width: 100%; object-fit: scale-down" />`;
    }
    case 'twitterImage':
      return `<img src="/resources/img/twitter.png" model="${field}" alt="${field}" contenteditable="false" />`;

    case 'facebookImage':
      return `<img src="/resources/img/facebook.png" model="${field}" alt="${field}" contenteditable="false" />`;

    case 'instagramImage':
      return `<img src="/resources/img/instagram.png" model="${field}" alt="${field}" contenteditable="false" />`;

    case 'youtubeImage':
      return `<img src="/resources/img/youtube.png" model="${field}" alt="${field}" contenteditable="false" />`;

    case 'googleplusImage':
      return `<img src="/resources/img/googleplus.png" model="${field}" alt="${field}" contenteditable="false" />`;

    case 'appstoreImage':
      return `<img src="/resources/img/appstore.png" model="${field}" alt="${field}" contenteditable="false" style="width: 190px;margin: 15px;transform: translateY(17px);" />`;

    case 'googleplayImage':
      return `<img src="/resources/img/ggplay.png" model="${field}" alt="${field}" contenteditable="false" width="240px" height="auto" style="transform: translateY(17px);" />`;

    default:
      return '';
  }
};

const htmlToFreemarker = (str, editor, addHtmlDocType) => {
  let string;
  if (editor) {
    const alreadyProcessedLinkRegexp = new RegExp(/\${(.+?)}| /g);
    const emptyRegex = /(.|\s)*\S(.|\s)*/gm;
    const aList = editor.document.getElementsByTag('a');
    for (let i = 0, count = aList.count(); i < count; i += 1) {
      const element = aList.getItem(i);
      // inutile de replacer les attributs qui existent
      if (
        element.hasAttribute('href') &&
        !element.getAttribute('href').match(alreadyProcessedLinkRegexp)
      ) {
        if (element.getAttribute('href').contains('bookingLink')) {
          element.setAttribute('href', '<@bookingLink />');
        } else if (element.getAttribute('href').contains('invoiceURL')) {
          element.setAttribute('href', '<@invoiceURL />');
        } else if (element.getAttribute('href').contains('contractURL')) {
          element.setAttribute('href', '<@contractURL />');
        } else if (element.getAttribute('href').contains('saleLink')) {
          element.setAttribute('href', '<@saleLink />');
        } else if (element.getAttribute('href').contains('wizvilleentryLink')) {
          element.setAttribute('href', '<@wizvilleentryLink />');
        } else if (element.getAttribute('href').contains('mirrorLink')) {
          element.setAttribute('href', '<@mirrorLink />');
        } else if (element.getAttribute('href').contains('definePasswordLink')) {
          element.setAttribute('href', '<@definePasswordLink />');
        } else if (element.getAttribute('href').contains('unsubscribeLink')) {
          element.setAttribute('href', '<@unsubscribeLink />');
        } else if (element.getAttribute('href').contains('supplierLink')) {
          element.setAttribute('href', '<@supplierLink />');
        } else if (element.getAttribute('href').contains('passwordForgottenLink')) {
          element.setAttribute('href', '<@passwordForgottenLink />');
        } else if (element.getAttribute('href').contains('passwordMAJLink')) {
          element.setAttribute('href', '<@passwordMAJLink />');
        } else if (element.getAttribute('href').contains('personalEquipmentLink')) {
          element.setAttribute('href', '<@personalEquipmentLink />');
        } else if (element.getAttribute('href').contains('resamaniaLoginLink')) {
          element.setAttribute('href', '<@resamaniaLoginLink />');
        } else if (element.getAttribute('href').contains('instagramLink')) {
          element.setAttribute('href', '<@instagramLink />');
        } else if (element.getAttribute('href').contains('facebookLink')) {
          element.setAttribute('href', '<@facebookLink />');
        } else if (element.getAttribute('href').contains('googleplusLink')) {
          element.setAttribute('href', '<@googleplusLink />');
        } else if (element.getAttribute('href').contains('clubLink')) {
          element.setAttribute('href', '<@clubLink />');
        } else if (element.getAttribute('href').contains('twitterLink')) {
          element.setAttribute('href', '<@twitterLink />');
        } else if (element.getAttribute('href').contains('youtubeLink')) {
          element.setAttribute('href', '<@youtubeLink />');
        } else if (element.getAttribute('href').contains('invitationLink')) {
          element.setAttribute('href', '<@invitationLink />');
        } else if (element.getAttribute('href').startsWith('mailto:')) {
          element.setAttribute('href', `<@emailLink url="${element.getAttribute('href')}" />`);
        } else if (element.getAttribute('href') && emptyRegex.test(element.getAttribute('href'))) {
          element.setAttribute('href', `<@externalLink url="${element.getAttribute('href')}" />`);
        }
        element.setAttribute('data-cke-saved-href', element.getAttribute('href'));
      }
    }
    const wizBlockEls = editor.document.getElementsByTag('wizblock');
    while (wizBlockEls.getItem(0) !== null) {
      wizBlockEls.getItem(0).$.replaceWith('<@wizvilleBlock />');
    }

    const imgList = editor.document.getElementsByTag('img');

    for (let i = 0, count = imgList.count(); i < count; i += 1) {
      const element = imgList.getItem(i);
      const style = element.getAttribute('style');

      if (style) {
        // Get the width from the style.
        let match = /(?:^|\s)width\s*:\s*(\d+?)px/i.exec(style);
        const width = match && match[1];

        // Get the height from the style.
        match = /(?:^|\s)height\s*:\s*(\d+?)px/i.exec(style);
        const height = match && match[1];

        if (width) element.setAttribute('width', width);
        if (height) element.setAttribute('height', height);
      }
    }
    string = editor.getData();

    // les balises script sont innacessibles car cke_protected + escaped '<'=>x3C
    // on parse en html pour retirer les <scripts> puis on reparse en string
    const scriptTagRegex = /script>.*?script>/gm;
    if (scriptTagRegex.test(string)) {
      const docFromString = new DOMParser().parseFromString(string, 'text/html');
      const scriptEls = docFromString.getElementsByTagName('script');
      while (scriptEls[0]) {
        scriptEls[0].replaceWith('');
      }
      string =
        docFromString?.documentElement?.outerHTML ||
        (XMLSerializer && new XMLSerializer().serializeToString(docFromString)) ||
        string;
    }
  } else {
    string = str;
  }

  // champs qui correnspondent au résultat de getMacroImageValue
  string = string.replace(
    /<img src=".+?" model="(.+?)" alt=".+?" contenteditable="false".*?\/>/g,
    '<@$1 type="image" />'
  );

  string = string.replace(
    /<img model="(.+?)" alt=".+?" contenteditable="false".*?\/>/g,
    '<@$1 type="image" />'
  );

  // get tables
  // string = string.replace(
  //   /<input(.+?)alt="(.+?)"(.+?)table="true"(.+?)type="button"(.+?)value="(.+?)"(.+?)\/>/g,
  //   '<@$2 tableTitle="$6"/>'
  // );

  // CONTACT CENTER FIELDS
  string = string.replace(
    /<input alt=['|"](contactCenterMail|contactCenterPhone)['|"](.+?)type=['|"]button['|"](.+?)value=['|"](.+?)['|"](.+?)\/>/g,
    `<@$1 />`
  );

  // get tags
  string = string.replace(
    /<input alt=['|"](.+?)['|"](.+?)type=['|"]button['|"](.+?)value=['|"](.+?)['|"](.+?)\/>/g,
    `\${$1!""}`
  );

  // get app images
  string = string.replace(
    /<div model="getAppImage" alt="${getAppImage}><img(.+?)\/><img(.+?)\/><div\/>/g,
    '<@getAppImage />'
  );

  string = utils.unescapeHtml(string);

  // suppression des doubles quotes gênants
  // removeUselessQuotes
  // on enleve les doubles quotes des balises <a> pour éviter les problemes d'interpretation
  string = utils.replaceAll(string, /<a(.+)>(.+?)<\/a>/g, (match, p1) => {
    const newLinkProperties = utils.replaceAll(p1, ' *=""', ' ');
    const result = utils.replaceAll(match, utils.escapeRegExp(p1), newLinkProperties);
    return result;
  });

  if (!!addHtmlDocType && /^<!DOCTYPE/i.test(string)) {
    string = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">${string}`;
  }
  return string;
};

const htmlToFreemarkerSms = pHtml => smsUtils.htmlToFreemarkerSms(pHtml);

const freemarkerToHtml = (pFreemarker, fieldsModels, clubParams) => {
  let html = pFreemarker;
  fieldsModels.forEach(field => {
    if (field.type !== 'LINK' && field.type !== 'TABLE' && field.type !== 'IMAGE') {
      switch (field.type) {
        case 'INTEGER':
          html = html.split(`\${${field.model}?string.computer}`).join(field.value);
          break;
        default:
          html = html.split(`\${${field.model}!""}`).join(field.value);
      }
    }
  });

  html = html.replace(/<@wizvilleBlock \/>/g, getWizBlock());
  html = html.replace(
    /<@(contactCenterMail|contactCenterPhone) \/>/g,
    (match, p1) => fieldsModels.filter(m => m.model === p1)[0].value
  );

  html = html.replace(/<@externalLink url="(.*?)" \/>/g, '$1');

  html = html.replace(/<@([a-zA-Z0-9]*) type="image"(.*?)\/>/g, (match, field) =>
    getMacroImageValue(field, clubParams)
  );

  // set mailto
  html = html.replace(/<@emailLink url="(.+?)"(.*?)\/>/g, '$1');

  html = html.replace(/<@(.*?) \/>/g, '$1');

  return html;
};

const smsFreemarkerToHtml = (pFreemarker, fieldsModels) => {
  let html = pFreemarker;
  fieldsModels.forEach(field => {
    if (field.type !== 'LINK' && field.type !== 'TABLE')
      html = html.split(`\${${field.model}!""}`).join(field.value);
  });

  html = html.replace(
    /<@(contactCenterMail|contactCenterPhone) \/>/g,
    (match, p1) => fieldsModels.filter(m => m.model === p1)[0].value
  );

  // set mailto
  html = html.replace(
    /<@emailLink text="(.+?)" mailto="(.*?)"(.*?)\/>/g,
    '<a href="mailto:$2">$1</a>'
  );

  html = html.replace(/<@externalLink url="(.+?)" \/>/g, '$1');

  // set internal link
  html = html.replace(
    /<@(.+?) track="false"\/>/g,
    (c, p1) =>
      `<a href="${p1}" model="${p1}" alt="internalLink">${utils.getLang(
        `smartmessaging.resultfield.${p1}`
      )}</a>`
  );
  html = html.replace(
    /<@(.+?) \/>/g,
    (c, p1) =>
      `<a href="${p1}" model="${p1}" alt="internalLink">${utils.getLang(
        `smartmessaging.resultfield.${p1}`
      )}</a>`
  );

  html = html.replace(/\n/g, '<br>');
  return html;
};

const processMailContentFields = (
  contentFields,
  clubParams,
  excludeTypes = { all: [], object: [], content: [] }
) => {
  const processedFieldsObject = {
    fieldsModels: [],
    fields: { objectFields: [], contentFields: [], linkFields: [] },
  };
  Object.keys(contentFields)
    .filter(fk => excludeTypes.all.indexOf(contentFields[fk]) === -1)
    .forEach(fieldKey => {
      let value = '';
      let model = '';
      const type = contentFields[fieldKey];
      switch (type) {
        case 'DATE':
          model = `(model.${fieldKey}!"2000-01-01")`;
          value = `<input alt='(model.${fieldKey}!"2000-01-01")' type='button' value='${utils.getLang(
            `smartmessaging.resultfield.${fieldKey}`
          )}' />`;
          processedFieldsObject.fields.objectFields.push({
            name: utils.getLang(`smartmessaging.resultfield.${fieldKey}`),
            value,
          });
          break;
        case 'TIME':
          value = `<input alt='(model.${fieldKey}!"00:00")' type='button' value='${utils.getLang(
            `smartmessaging.resultfield.${fieldKey}`
          )}' />`;
          model = `(model.${fieldKey}!"00:00")`;
          processedFieldsObject.fields.objectFields.push({
            name: utils.getLang(`smartmessaging.resultfield.${fieldKey}`),
            value,
          });
          break;
        case 'LINK':
          value = `<a href="${fieldKey}" model="${fieldKey}" alt="internalLink">${utils.getLang(
            `smartmessaging.resultfield.${fieldKey}`
          )}</a>`;
          processedFieldsObject.fields.linkFields.push({
            name: utils.getLang(`smartmessaging.resultfield.${fieldKey}`),
            value: `\${model.${fieldKey}!""}`,
          });
          break;

        case 'IMAGE':
          value = getMacroImageValue(fieldKey, clubParams);
          model = fieldKey;
          break;
        case 'INTEGER':
          value = `<input alt='model.${fieldKey}?string.computer' type='button' value='${utils.getLang(
            `smartmessaging.resultfield.${fieldKey}`
          )}' />`;
          model = `model.${fieldKey}?string.computer`;
          processedFieldsObject.fields.objectFields.push({
            name: utils.getLang(`smartmessaging.resultfield.${fieldKey}`),
            value,
          });
          break;
        case 'STRING':
          if (fieldKey === 'contactCenterMail' || fieldKey === 'contactCenterPhone') {
            value = `<input alt='${fieldKey}' type='button' value="${utils.getLang(
              `smartmessaging.resultfield.${fieldKey}`
            )}" />`;
            model = fieldKey;
            processedFieldsObject.fields.objectFields.push({
              name: utils.getLang(`smartmessaging.resultfield.${fieldKey}`),
              value,
            });
          } else {
            value = `<input alt='model.${fieldKey}' type='button' value="${utils.getLang(
              `smartmessaging.resultfield.${fieldKey}`
            )}" />`;
            model = `model.${fieldKey}`;
            processedFieldsObject.fields.objectFields.push({
              name: utils.getLang(`smartmessaging.resultfield.${fieldKey}`),
              value,
            });
            processedFieldsObject.fields.linkFields.push({
              name: utils.getLang(`smartmessaging.resultfield.${fieldKey}`),
              value: `\${model.${fieldKey}!""}`,
            });
          }
          break;
        case 'WIZVILLEBLOCK':
          value = getWizBlock();
          model = fieldKey;
          break;
        default:
          value = `<input alt='model.${fieldKey}' type='button' value="${utils.getLang(
            `smartmessaging.resultfield.${fieldKey}`
          )}" />`;
          model = `model.${fieldKey}`;
          processedFieldsObject.fields.objectFields.push({
            name: utils.getLang(`smartmessaging.resultfield.${fieldKey}`),
            value,
          });
          processedFieldsObject.fields.linkFields.push({
            name: utils.getLang(`smartmessaging.resultfield.${fieldKey}`),
            value: `\${model.${fieldKey}!""}`,
          });
      }
      processedFieldsObject.fieldsModels.push({
        name: utils.getLang(`smartmessaging.resultfield.${fieldKey}`),
        value,
        type: contentFields[fieldKey],
        model,
      });
      processedFieldsObject.fields.contentFields.push({
        name: utils.getLang(`smartmessaging.resultfield.${fieldKey}`),
        id: fieldKey,
        value,
        type: contentFields[fieldKey],
      });
    });
  return processedFieldsObject;
};

const processSmsContentFields = contentFields => {
  const processedFieldsObject = {
    fieldsModels: [],
    fields: [],
  };
  Object.keys(contentFields)
    .filter(k => ['IMAGE', 'LINK'].indexOf(contentFields[k]) < 0)
    .forEach(fieldKey => {
      let value = '';
      let model = '';
      switch (contentFields[fieldKey]) {
        case 'DATE':
          model = `(model.${fieldKey}!"2000-01-01")`;
          value = `<input alt='(model.${fieldKey}!"2000-01-01")' type='button' value='${utils.getLang(
            `smartmessaging.resultfield.${fieldKey}`
          )}' />`;
          break;
        case 'TIME':
          value = `<input alt='(model.${fieldKey}!"00:00")' type='button' value='${utils.getLang(
            `smartmessaging.resultfield.${fieldKey}`
          )}' />`;
          model = `(model.${fieldKey}!"00:00")`;
          break;
        case 'LINK':
          value = `<a href="${fieldKey}" model="${fieldKey}" alt="internalLink">${utils.getLang(
            `smartmessaging.resultfield.${fieldKey}`
          )}</a>`;
          model = fieldKey;
          break;
        case 'STRING':
          if (fieldKey === 'contactCenterMail' || fieldKey === 'contactCenterPhone') {
            value = `<input alt='${fieldKey}' type='button' value="${utils.getLang(
              `smartmessaging.resultfield.${fieldKey}`
            )}" />`;
            model = fieldKey;
          } else {
            value = `<input alt='model.${fieldKey}' type='button' value="${utils.getLang(
              `smartmessaging.resultfield.${fieldKey}`
            )}" />`;
            model = `model.${fieldKey}`;
          }
          break;
        default:
          value = `<input alt="model.${fieldKey}" type="button" value="${utils.getLang(
            `smartmessaging.resultfield.${fieldKey}`
          )}" />`;
          model = `model.${fieldKey}`;
          break;
      }
      processedFieldsObject.fieldsModels.push({
        name: utils.getLang(`smartmessaging.resultfield.${fieldKey}`),
        value,
        type: contentFields[fieldKey],
        model,
      });
      processedFieldsObject.fields.push({
        name: utils.getLang(`smartmessaging.resultfield.${fieldKey}`),
        id: fieldKey,
        value,
        type: contentFields[fieldKey],
      });
    });
  return processedFieldsObject;
};

const processContentFields = (contentFields, actionType, clubParams) => {
  switch (actionType) {
    case ActionType.SMS.id:
      return processSmsContentFields(contentFields, clubParams);
    case ActionType.EMAIL.id:
    case ActionType.EMAIL_COACH.id:
    case ActionType.EMAIL_SPONSORSHIP.id:
    case ActionType.EMAIL_INVITATION.id:
      return processMailContentFields(contentFields, clubParams);
    case ActionType.MEMBER_NOTIFICATION.id:
      return processMailContentFields(contentFields, clubParams, {
        all: ['IMAGE'],
      });
    case ActionType.PUSH_XPLORACTIVE.id:
      return processMailContentFields(contentFields, clubParams, {
        all: ['IMAGE', 'LINK'],
      });
    default:
      break;
  }
  return null;
};

const sortGenericMessageModels = messageModelList => {
  const genericModels = [];
  const readyModels = [];
  messageModelList.forEach(messageModel => {
    if (messageModel.generic) {
      genericModels.push(messageModel);
    } else {
      readyModels.push(messageModel);
    }
  });
  return {
    genericModels: readyModels.map(model => ({ ...model, generic: true })).concat(genericModels),
    readyModels,
  };
};

const getContentUsedField = content => {
  const fieldsMatchRegex = new RegExp(/\${model\.([a-z0-9]*)(.*?)}/g);
  const matches = [...content.matchAll(fieldsMatchRegex)];
  if (matches) {
    return matches.map(m => m[1]); // m[1] is the capture, and should be a field name
  }
  return [];
};

const replaceContentFieldsForCount = obj => {
  if (!obj) return obj;
  const objWithoutField = obj.replace(
    /<input alt=["|'](.+?)["|'] type=["|']button["|'] value=["|'](.+?)["|'][ |]\/>/gi,
    '1234567890'
  );

  return objWithoutField;
};

const countTextLength = obj => {
  const fieldFreeObj = replaceContentFieldsForCount(obj || '');
  return fieldFreeObj ? fieldFreeObj.length || 0 : 0;
};

const checkMailContentValidity = content => {
  const invalidities = { obj: [], content: [] };
  const objLength = countTextLength(content?.object ?? '');
  let objIsValid = true;
  let contentIsValid = true;
  if (objLength === 0) {
    objIsValid = false;
    invalidities.obj.push(utils.getLang('smartmessaging.contentEditor.mail.objectIsEmpty'));
  } else if (objLength > 255) {
    objIsValid = false;
    invalidities.obj.push(
      utils.stringFormat(utils.getLang('smartmessaging.error.mail.object.tooLong'), [objLength])
    );
  }
  if (!content.content) {
    contentIsValid = false;
    invalidities.content.push(utils.getLang('smartmessaging.contentEditor.mail.contentIsEmpty'));
  }

  return {
    isValid: objIsValid && contentIsValid,
    invalidities,
    hasOptoutLink: !!content && !!content.content && content.content.includes('unsubscribeLink'),
    objIsValid,
    contentIsValid,
  };
};

const checkXplorActiveNotifContentValidity = content => {
  const invalidities = { obj: [], content: [] };
  const objLength = countTextLength(content?.object ?? '');
  const ctnLength = countTextLength(content?.content ?? '');

  let objIsValid = true;
  let contentIsValid = true;
  if (objLength === 0) {
    objIsValid = false;
    invalidities.obj.push(utils.getLang('smartmessaging.contentEditor.mail.objectIsEmpty'));
  } else if (objLength > 255) {
    objIsValid = false;
    invalidities.obj.push(
      utils.stringFormat(utils.getLang('smartmessaging.error.mail.object.tooLong'), [objLength])
    );
  }
  if (content.content && ctnLength > 1000) {
    contentIsValid = false;
    invalidities.content.push(
      utils.stringFormat(utils.getLang('smartmessaging.error.xploractivepush.content.tooLong'), [
        ctnLength,
        1000,
      ])
    );
  }
  if (!content.content) {
    contentIsValid = false;
    invalidities.content.push(utils.getLang('smartmessaging.contentEditor.mail.contentIsEmpty'));
  }

  return {
    isValid: objIsValid && contentIsValid,
    invalidities,
    objIsValid,
    contentIsValid,
  };
};

export default {
  freemarkerToHtml,
  getContentUsedField,
  htmlToFreemarker,
  htmlToFreemarkerSms,
  countTextLength,
  checkMailContentValidity,
  checkXplorActiveNotifContentValidity,
  processContentFields,
  processMailContentFields,
  processSmsContentFields,
  smsFreemarkerToHtml,
  sortGenericMessageModels,
};
