import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Fade,
  Table,
  TableHead,
  TableSortLabel,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  IconButton,
  InputLabel,
  Typography,
  Button,
} from '@mui/material';
import VisibleIcon from '@mui/icons-material/Visibility';
import { format, parseISO, compareAsc, compareDesc } from 'date-fns';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import uuid from 'uuid';
import DownloadIcon from '@mui/icons-material/VerticalAlignBottom';
import ContentViewer from './ContentViewer';
import Card from '../common/Card';
import Pagination from '../common/pagination/Pagination';

import utils from 'src/utils/utils';
import apiClient from 'src/utils/apiClient';
import withApiCaller from '../common/hocs/withApiCaller';
import filtrable from '../common/hocs/filtrable';

import withAlertAndLoader from '../common/hocs/withAlertAndLoader';
import ActionType, { ActionTypeById } from 'src/enums/actionType';

const StyledTableSortLabelRender = ({ classes, children, ...props }) => (
  <TableSortLabel classes={{ root: classes.root, active: classes.active }} {...props}>
    {children}
  </TableSortLabel>
);

StyledTableSortLabelRender.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

const nullTest = (a, b, test) => {
  function isNullOrUndefined(v) {
    return v === null || v === undefined;
  }
  if (isNullOrUndefined(a) && isNullOrUndefined(b)) {
    return 0;
  }
  if (isNullOrUndefined(a)) return -1;
  if (isNullOrUndefined(b)) return 1;
  return test();
};

const sorters = {
  default: orderBy => ({
    asc: (a, b) =>
      nullTest(a[orderBy], b[orderBy], () => a[orderBy].toString().localeCompare(b[orderBy])),
    desc: (a, b) =>
      nullTest(b[orderBy], a[orderBy], () => b[orderBy].toString().localeCompare(a[orderBy])),
  }),
  STRING: orderBy => ({
    asc: (a, b) =>
      nullTest(a[orderBy], b[orderBy], () => {
        if (a[orderBy].toLowerCase().trim() < b[orderBy].toLowerCase().trim()) return -1;
        if (a[orderBy].toLowerCase().trim() > b[orderBy].toLowerCase().trim()) return 1;
        return 0;
      }),
    desc: (a, b) =>
      nullTest(b[orderBy], a[orderBy], () => {
        if (a[orderBy].toLowerCase().trim() > b[orderBy].toLowerCase().trim()) return -1;
        if (a[orderBy].toLowerCase().trim() < b[orderBy].toLowerCase().trim()) return 1;
        return 0;
      }),
  }),

  INTEGER: orderBy => ({
    asc: (a, b) =>
      nullTest(a[orderBy], b[orderBy], () => {
        if (parseInt(a[orderBy], 10) < parseInt(b[orderBy], 10)) return -1;
        if (parseInt(a[orderBy], 10) > parseInt(b[orderBy], 10)) return 1;
        return 0;
      }),
    desc: (a, b) =>
      nullTest(b[orderBy], a[orderBy], () => {
        if (parseInt(a[orderBy], 10) > parseInt(b[orderBy], 10)) return -1;
        if (parseInt(a[orderBy], 10) < parseInt(b[orderBy], 10)) return 1;
        return 0;
      }),
  }),
  DATE: orderBy => ({
    asc: (a, b) =>
      nullTest(a[orderBy], b[orderBy], () =>
        compareAsc(parseISO(a[orderBy]), parseISO(b[orderBy]))
      ),
    desc: (a, b) =>
      nullTest(b[orderBy], a[orderBy], () =>
        compareDesc(parseISO(a[orderBy]), parseISO(b[orderBy]))
      ),
  }),
  EMAILCONTENT: () => 0,
};

const colTypes = {
  createDate: 'DATE',
  openDate: 'DATE',
  opened: 'BOOL',
  content: 'CONTENT',
  actionTypeId: 'TARGET_TYPE',
  ...Object.values(ActionType).reduce(
    (defaultValueByActionTypeMap, actionType) => ({
      ...defaultValueByActionTypeMap,
      [actionType.id]: {},
    }),
    {}
  ),
};

function MailViewerRender({ contentId, object, callApi, showAlert }) {
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState(null);
  async function onOpen() {
    if (!content) {
      const ctn = await callApi(apiClient.getSentMessageContent, [contentId]);
      if (!ctn) {
        showAlert({ msg: utils.getLang('smartmessaging.sentMessages.deletedContent') });
      } else {
        setContent(ctn);
        setOpen(true);
      }
    }
  }

  return (
    <>
      {!!content && (
        <ContentViewer
          content={content}
          object={object}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
      <IconButton
        onClick={e => {
          e.preventDefault();
          onOpen();
        }}
        size="large"
      >
        <VisibleIcon />
      </IconButton>
    </>
  );
}
MailViewerRender.propTypes = {
  contentId: PropTypes.number.isRequired,
  object: PropTypes.string.isRequired,
  callApi: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
};
const MailViewer = withApiCaller(withAlertAndLoader(MailViewerRender));

const cellRenderers = {
  DATE: dateValue => (dateValue ? format(parseISO(dateValue), 'dd/MM/yyyy HH:mm') : '-'),
  CONTENT: (id, line) => <MailViewer contentId={id} object={line.object} />,
  BOOL: val => (val ? utils.getLang('smartmessaging.yes') : utils.getLang('smartmessaging.no')),
  TARGET_TYPE: val =>
    utils.getLang(`smartmessaging.sentMessages.targetType.${ActionTypeById[val]}`),
};

const renderCell = (column, messageType) =>
  cellRenderers[colTypes[messageType][column]] || cellRenderers[colTypes[column]] || (v => v);

const getSorter = ({ order, orderBy, type }) => {
  if (orderBy === 'unset') return v => v;
  return sorters[type] ? sorters[type](orderBy)[order] : sorters.default(orderBy)[order];
};

const useSummaryStyles = makeStyles()(() => ({
  typo: { fontSize: '1rem', textAlign: 'center' },
}));

const Summary = ({ from, to, count, messageType }) => {
  const { classes } = useSummaryStyles();
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
        <Card
          title={
            <div style={{ textAlign: 'center' }}>
              {utils.getLang('smartmessaging.sentMessages.messageType')}
            </div>
          }
        >
          <Typography className={classes.typo} color="textSecondary">
            {utils.getLang(`smartmessaging.contentType.${ActionTypeById[messageType]}`)}
          </Typography>
        </Card>
      </div>
      <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
        <Card
          title={
            <div style={{ textAlign: 'center' }}>
              {utils.getLang('smartmessaging.sentMessages.period')}
            </div>
          }
        >
          <Typography color="textSecondary" className={classes.typo}>
            {utils.stringFormat(utils.getLang(`smartmessaging.sentMessages.fromTo`), [from, to])}
          </Typography>
        </Card>
      </div>
      <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
        <Card
          title={
            <div style={{ textAlign: 'center' }}>
              {utils.getLang('smartmessaging.sentMessages.sentNumber')}
            </div>
          }
        >
          <Typography color="textSecondary" className={classes.typo}>
            {count.total}
          </Typography>
          {messageType === ActionType.EMAIL.id && (
            <>
              <Typography color="textSecondary" className={classes.typo}>
                {utils.getLang('smartmessaging.sentMessages.customerSentCount')}:
                {count.customerCount}
              </Typography>
              <Typography color="textSecondary" className={classes.typo}>
                {utils.getLang('smartmessaging.sentMessages.coachSentCount')}:{count.coachCount}
              </Typography>
              <Typography color="textSecondary" className={classes.typo}>
                {utils.getLang('smartmessaging.sentMessages.sponsorSentCount')}:{count.sponsorCount}
              </Typography>
              <Typography color="textSecondary" className={classes.typo}>
                {utils.getLang('smartmessaging.sentMessages.invitedSentCount')}:{count.invitedCount}
              </Typography>
            </>
          )}
        </Card>
      </div>
      <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
        <Card
          title={
            <div style={{ textAlign: 'center' }}>
              {utils.getLang('smartmessaging.sentMessages.openedNumber')}
            </div>
          }
        >
          <Typography color="textSecondary" className={classes.typo}>
            {count.openedCount}
          </Typography>
          {messageType === ActionType.EMAIL.id && (
            <>
              <Typography color="textSecondary" className={classes.typo}>
                {utils.getLang('smartmessaging.sentMessages.customerOpenedCount')}:
                {count.custOpenedCount}
              </Typography>
              <Typography color="textSecondary" className={classes.typo}>
                {utils.getLang('smartmessaging.sentMessages.coachOpenedCount')}:
                {count.coachOpenedCount}
              </Typography>
              <Typography color="textSecondary" className={classes.typo}>
                {utils.getLang('smartmessaging.sentMessages.sponsorOpenedCount')}:
                {count.sponsorOpenedCount}
              </Typography>
              <Typography color="textSecondary" className={classes.typo}>
                {utils.getLang('smartmessaging.sentMessages.invitedOpenedCount')}:
                {count.invitedOpenedCount}
              </Typography>
            </>
          )}
        </Card>
      </div>
    </div>
  );
};
Summary.propTypes = {
  count: PropTypes.object.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  messageType: PropTypes.number.isRequired,
};

const FilterButtons = ({ setFilter, filter }) => {
  const theme = useTheme();
  return (
    <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
      <InputLabel>{`${utils.getLang('smartmessaging.sentMessages.displayFilter')}`} </InputLabel>
      <Button
        variant={!filter ? 'outlined' : 'text'}
        style={{ marginLeft: '8px' }}
        onClick={e => {
          e.preventDefault();
          setFilter();
        }}
      >
        {utils.getLang('smartmessaging.sentMessages.filter.NONE')}
      </Button>
      <Button
        variant={filter === 'customers' ? 'outlined' : 'text'}
        style={{ color: theme.palette.text.secondary }}
        onClick={e => {
          e.preventDefault();
          setFilter('customers');
        }}
      >
        {utils.getLang('smartmessaging.sentMessages.filter.CUSTOMERS')}
      </Button>
      <Button
        variant={filter === 'coaches' ? 'outlined' : 'text'}
        style={{ color: theme.palette.text.secondary }}
        onClick={e => {
          e.preventDefault();
          setFilter('coaches');
        }}
      >
        {utils.getLang('smartmessaging.sentMessages.filter.COACHES')}
      </Button>
      <Button
        variant={filter === 'godfather' ? 'outlined' : 'text'}
        style={{ color: theme.palette.text.secondary }}
        onClick={e => {
          e.preventDefault();
          setFilter('godfather');
        }}
      >
        {utils.getLang('smartmessaging.sentMessages.filter.SPONSOR')}
      </Button>
      <Button
        variant={filter === 'invite' ? 'outlined' : 'text'}
        style={{ color: theme.palette.text.secondary }}
        onClick={e => {
          e.preventDefault();
          setFilter('invite');
        }}
      >
        {utils.getLang('smartmessaging.sentMessages.filter.INVITE')}
      </Button>
    </div>
  );
};

FilterButtons.propTypes = {
  setFilter: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
};

const useStyles = makeStyles()((theme, _params, classes) => ({
  active: { color: theme.palette.primary.main },
  root: {
    color: theme.palette.primary.main,
    [`&.${classes.active}`]: {
      color: theme.palette.primary.main,
      '& svg path': { color: theme.palette.primary.main },
    },
  },
  cell: { padding: '4px', color: theme.palette.text.secondary },
}));

function SentMessagesList({
  type,
  sentMessages,
  sortConfig,
  setSortConfig,
  count,
  from,
  to,
  getExport,
  setFilters,
  filteredData,
  selectedFilters,
}) {
  const cols = (sentMessages && sentMessages.length && Object.keys(sentMessages[0])) || [];
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const { classes } = useStyles();

  return (
    <Fade in timeout={500}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflow: 'hidden',
          padding: '10px ',
        }}
      >
        <Card
          title={
            <Summary
              {...{
                from: format(from, 'dd/MM/yyyy'),
                to: format(to, 'dd/MM/yyyy'),
                count,
                messageType: type,
              }}
            />
          }
          action={
            <IconButton
              onClick={e => {
                e.preventDefault();
                getExport();
              }}
              size="large"
            >
              <DownloadIcon />
            </IconButton>
          }
        />
        {type === ActionType.EMAIL.id && (
          <FilterButtons
            setFilter={f => (f ? setFilters([{ value: f }], true) : setFilters([], true))}
            filter={selectedFilters.length ? selectedFilters[0].value : ''}
          />
        )}
        <div
          style={{
            margin: '2px 0 0 4px',
            overflow: 'auto',
            height: '100%',
          }}
        >
          {!!filteredData && (
            <Table>
              <TableHead>
                <TableRow>
                  {cols.map(column => (
                    <TableCell
                      key={column}
                      style={{
                        position: 'sticky',
                        top: 0,
                        padding: '4px',
                        zIndex: 1,
                      }}
                    >
                      <TableSortLabel
                        classes={{ root: classes.root, active: classes.active }}
                        key={column}
                        active={sortConfig.orderBy === column}
                        direction={sortConfig.order}
                        onClick={() => {
                          setSortConfig({
                            order: sortConfig.order === 'asc' ? 'desc' : 'asc',
                            orderBy: column,
                          });
                        }}
                      >
                        {utils.getLang(`smartmessaging.sentMessage.columnLabel.${column}`)}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .sort(
                    getSorter({
                      order: sortConfig.order,
                      orderBy: sortConfig.orderBy,
                      type:
                        colTypes[type][sortConfig.orderBy] ||
                        colTypes[sortConfig.orderBy] ||
                        'default',
                    })
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(singleEntry => (
                    <TableRow key={uuid()} hover>
                      {cols.map(column => (
                        <TableCell className={classes.cell} key={uuid()}>
                          {renderCell(column, type)(singleEntry[column], singleEntry, column)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </div>
        <Table>
          <TableFooter>
            <TableRow>
              <Pagination
                pages={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Fade>
  );
}

SentMessagesList.propTypes = {
  type: PropTypes.number.isRequired,
  sentMessages: PropTypes.array.isRequired,
  sortConfig: PropTypes.object.isRequired,
  setSortConfig: PropTypes.func.isRequired,
  count: PropTypes.object.isRequired,
  from: PropTypes.PropTypes.instanceOf(Date).isRequired,
  to: PropTypes.PropTypes.instanceOf(Date).isRequired,
  getExport: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  filteredData: PropTypes.array.isRequired,
  selectedFilters: PropTypes.array.isRequired,
};

const filterCfg = props => ({
  defaultFilters: [],
  filtrableData: props.sentMessages,
  runAlways: true,
  filterFn: filters => item => {
    if (!filters.length) return true;
    switch (filters[0].value) {
      case 'coaches':
        return ActionType.EMAIL_COACH.id === item.actionTypeId;
      case 'customers':
        return ActionType.EMAIL.id === item.actionTypeId;
      case 'godfather':
        return ActionType.EMAIL_SPONSORSHIP.id === item.actionTypeId;
      case 'invite':
        return ActionType.EMAIL_INVITATION.id === item.actionTypeId;
      default:
        return true;
    }
  },
});

export default filtrable(filterCfg)(SentMessagesList);
