import React from 'react';
import { connect } from 'react-redux';

import CtnLang from './CtnLang';
import utils from 'src/utils/utils';
import EmailObjectLabel from './EmailObjectLabel';
import XplorActiveNotifyObjectEditor from 'src/components/common/contentEditor/XplorActiveNotifyObjectEditor';
import EmailContentLabel from './EmailContentLabel';
import XplorActiveNotifyContentEditor from 'src/components/common/contentEditor/XplorActiveNotifyContentEditor';
import { ProcessedFieldType } from 'src/types/types';

interface XplorActiveNotifEditorProps {
  isWritable: boolean;
  onContentChange: (content: string) => void;
  onObjectChange: (object: string) => void;
  fields: {
    fields: {
      contentFields: ProcessedFieldType;
      objectFields: ProcessedFieldType;
      linkFields: ProcessedFieldType;
    };
  };
  currentAction: {
    content?: { content: string; object: string };
    customTemplate?: { generic?: boolean };
  };
}

const XplorActiveNotifEditor = ({
  currentAction,
  fields,
  onContentChange,
  onObjectChange,
  isWritable,
}: XplorActiveNotifEditorProps) => (
  <>
    <div style={{ width: '100%', display: 'flex' }}>
      <EmailObjectLabel
        title={utils.getLang('smartmessaging.contentEditor.xploractivepush.object')}
      />
      <div style={{ marginRight: 0, marginLeft: 'auto' }}>
        <CtnLang />
      </div>
    </div>
    <XplorActiveNotifyObjectEditor
      config={{
        readOnly: !isWritable,
        fields: fields.fields.objectFields,
      }}
      events={{
        change: event => {
          onObjectChange(event.editor.getData());
        },
      }}
      object={currentAction.content.object}
    />
    <EmailContentLabel
      skipOptoutControl
      title={utils.getLang('smartmessaging.contentEditor.xploractivepush.content')}
    />
    <XplorActiveNotifyContentEditor
      config={{
        readOnly: !isWritable,
        fields: fields.fields.contentFields,
      }}
      events={{
        change: event => {
          const data = event.editor.getData();
          onContentChange(data);
        },
        instanceReady: event => {
          if (currentAction.content.content) event.editor.setData(currentAction.content.content);
        },
      }}
    />
  </>
);

const mapStateToProps = state => ({
  currentAction: state.campaignEditor.actions.currentAction,
});

const actionCreators = {
  onContentChange: data => ({ type: 'ON_PUSH_NOTIFY_CONTENT_CHANGE', value: data }),
  onObjectChange: data => ({ type: 'ON_PUSH_NOTIFY_OBJECT_CHANGE', value: data }),
};

export default connect(mapStateToProps, actionCreators)(XplorActiveNotifEditor);
