import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ActionEditor from './ActionEditor';

import { mapActionsByTypeId } from 'src/reducers/campaignEditor';
import ActionCreator from './ActionCreator';
import ActionType from 'src/enums/actionType';

const lang = {
  title: {
    [ActionType.SMS.id]: 'smartmessaging.campaignAction.cardTitle.SMS',
    [ActionType.EMAIL.id]: 'smartmessaging.campaignAction.cardTitle.EMAIL',
    [ActionType.EMAIL_COACH.id]: 'smartmessaging.campaignAction.cardTitle.EMAIL',
    [ActionType.MEMBER_NOTIFICATION.id]:
      'smartmessaging.campaignAction.cardTitle.MEMBER_NOTIFICATION',
    [ActionType.EMAIL_SPONSORSHIP.id]: 'smartmessaging.campaignAction.cardTitle.EMAIL',
    [ActionType.EMAIL_INVITATION.id]: 'smartmessaging.campaignAction.cardTitle.EMAIL',
    [ActionType.PUSH_XPLORACTIVE.id]: 'smartmessaging.campaignAction.cardTitle.PUSH_XPLORACTIVE',
  },
  createText: {
    [ActionType.SMS.id]: 'smartmessaging.campaignAction.createTitle.SMS',
    [ActionType.EMAIL.id]: 'smartmessaging.campaignAction.createTitle.EMAIL',
    [ActionType.EMAIL_COACH.id]: 'smartmessaging.campaignAction.createTitle.EMAIL',
    [ActionType.MEMBER_NOTIFICATION.id]:
      'smartmessaging.campaignAction.createTitle.MEMBER_NOTIFICATION',
    [ActionType.EMAIL_SPONSORSHIP.id]: 'smartmessaging.campaignAction.createTitle.EMAIL',
    [ActionType.EMAIL_INVITATION.id]: 'smartmessaging.campaignAction.createTitle.EMAIL',
    [ActionType.PUSH_XPLORACTIVE.id]: 'smartmessaging.campaignAction.createTitle.PUSH_XPLORACTIVE',
  },
};

const CampaignActionItem = ({
  actionTypeId,
  campaignActionsByTypeId,
  loadFieldsAndContent,
  rmId,
  rmTypeId,
  goToCmpMsgStep,
  isWritable,
}) => (
  <>
    {(campaignActionsByTypeId[actionTypeId] &&
      campaignActionsByTypeId[actionTypeId].length &&
      campaignActionsByTypeId[actionTypeId].map(ca => (
        <div style={{ margin: '10px', width: '180px' }} key={ca.id}>
          <ActionEditor
            lang={lang}
            goToCmpMsgStep={goToCmpMsgStep}
            action={ca}
            actionTypeId={actionTypeId}
            loadFieldsAndContent={loadFieldsAndContent}
            isWritable={isWritable}
            rmId={rmId}
          />
        </div>
      ))) ||
      (isWritable && (
        <div style={{ margin: '10px', width: '180px' }} key={`ac-${actionTypeId}`}>
          <ActionCreator
            lang={lang}
            isWritable={isWritable}
            goToCmpMsgStep={goToCmpMsgStep}
            actionTypeId={actionTypeId}
            rmTypeId={rmTypeId}
            rmId={rmId}
            loadFieldsAndContent={loadFieldsAndContent}
          />
        </div>
      ))}
  </>
);

CampaignActionItem.propTypes = {
  actionTypeId: PropTypes.number.isRequired,
  rmTypeId: PropTypes.number.isRequired,
  campaignActionsByTypeId: PropTypes.object.isRequired,
  loadFieldsAndContent: PropTypes.func.isRequired,
  isWritable: PropTypes.bool.isRequired,
  goToCmpMsgStep: PropTypes.func.isRequired,
  rmId: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  campaignActionsByTypeId: mapActionsByTypeId(state),
});

export default connect(mapStateToProps)(CampaignActionItem);
