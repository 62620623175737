import React from 'react';
import CKEditor from '../ReactCKEditor';
import { CKEditorEvent } from 'src/types/types';

interface EmailObjectEditorProps {
  events?: { [key: string]: (ckEvent: CKEditorEvent) => void };
  config?: { [key: string]: unknown };
  object?: string;
  name?: string;
  container: React.MutableRefObject<HTMLDivElement>;
}

const EmailObjectEditor = ({
  name = 'objectEditor',
  object = '',
  container,
  events = {},
  config = {},
}: EmailObjectEditorProps) => (
  <CKEditor
    name={name}
    config={{
      removeButtons:
        'Underline,Subscript,Superscript,' +
        'Source,Anchor,Maximize,About,Copy,Paste,PasteText,PasteFromWord,' +
        'Cut,Scayt,Undo,Redo,Form,Checkbox,Radio,TextField,Textarea,Select,Button,' +
        'ImageButton,HiddenField,Bold,Italic,Underline,Strike,Subscript,Superscript,' +
        'RemoveFormat,NumberedList,BulletedList,Outdent,Indent,Blockquote,CreateDiv,' +
        'JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,' +
        'BidiLtr,BidiRtl,CreatePlaceholder,' +
        'Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,InsertPre,Styles,Format,Font,FontSize,Unlink,Link',
      extraPlugins: 'strinsert',
      removePlugins: 'colorbutton,elementspath',
      autoParagraph: false,
      enterMode: 2,
      height: 10 * (container.current.clientHeight / 100),
      ...config,
    }}
    events={events}
    content={object}
  />
);

export default EmailObjectEditor;
